import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay, instant = true) {

  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  useEffect(() => {
    function tick() {
      // console.log('Executing callback');
      savedCallback.current();
    }
    if (delay !== null) {
      if (instant) { tick(); }  // if instant is true, run the callback immediately
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, instant]);
}