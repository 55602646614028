import { useCallback, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { SET_WINDOW_DIMENSIONS } from "../actions/types";

export default function useResizeListener() {

  const dispatch = useDispatch();

  // Sets state.misc.windowDimensions value
	const onResize = useCallback(() => {
		const windowDimensions = { width: window.innerWidth, height: window.innerHeight };
		// console.log('updating windim: ', windowDimensions)
		dispatch({ type: SET_WINDOW_DIMENSIONS, payload: windowDimensions });
		// debounce(() => dispatch({ type: SET_WINDOW_DIMENSIONS, payload: windowDimensions }), 200);
	}, [dispatch]);

	// add onResize resize handler to listen for window resize and update Redux state.misc.windowDimensions value
	useEffect(() => {
		if (window) {
			onResize();
			window.addEventListener('resize', onResize);
			// window.addEventListener('resize', debounce(() => onResize, 200));
		}
		return () => {
			window.removeEventListener('resize', onResize);
		}
	}, [onResize]);

  
}