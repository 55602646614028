import * as Sentry from '@sentry/react';
import isbot from "isbot";

export default function initializeSentry(dsn, environment) {

  // Don't initialize Sentry if the user is a bot
  if (isbot(navigator.userAgent)) return;

  Sentry.init({
    dsn: dsn,
    ignoreErrors: ["acsbJS", "Loading CSS chunk"],
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          // "localhost", 
          /^https:\/\/v2.lexvid\.com\/api/
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: environment,
    // Performance Monitoring
    tracesSampleRate: 0, // Config to a number between 0 and 1
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) { // This function allows you to modify the event before it is sent to Sentry
      if(hint && hint.originalException && hint.originalException.isAxiosError) {
        if (hint.originalException.response && hint.originalException.response.data) {
          let contexts = {
            ...event.contexts
          };
          contexts.errorResponse = {
            data: hint.originalException.response.data
          };
          event.contexts = contexts;
        }
      }
      
      return event;
    },
  });
}