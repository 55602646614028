import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import * as Sentry from '@sentry/react';

//
axios.interceptors.request.use(
	config => {

		// console.log('intercepted: ', config.url);

		// Add device-id cookie and request header
		let deviceId = Cookies.get('device-id');

		if (!deviceId) {
			deviceId = uuidv4();
			Cookies.set('device-id', deviceId, { expires: 30 });
		}

		//
		config.headers['device-id'] = deviceId;
		// axios.defaults.headers.common['device-id'] = deviceId;


		// if (config.url.includes('state/shallow')) config.headers['ThrowError'] = true;

		return config;
	}, function (error) {
		Sentry.captureException(error);

		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => {
		// console.log('+++ response intercepted: ');
		// console.log(response);
		return response;
	},
	error => {

		let response = error.response;

		Sentry.setContext('Axios error response config', response.config);
		Sentry.setContext('Axios error response request', response.request);

		Sentry.setContext('Axios error', error);
		Sentry.captureException(error);

		if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Sentry.captureException(error.response.data); -- this doesn't really return anything useful
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
			// Does this work?
			// Sentry.captureException(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      // Sentry.captureException(error.message);
    }

		// if (store) {
		// 	const errorData = {
		// 		ErrorMessage: error?.response?.data?.ExceptionMessage,
		// 		Exception: error.toJSON(),
		// 		StackTrace: error?.response?.data?.StackTrace,
		// 		PageName: window.location.pathname, // TODO: need to get component name
		// 		FullURL: window.location.href,
		// 		InternalContext: store.getState()
		// 	}
		// 	// console.log('!!! --- logging API service error');
		// 	store.dispatch(logAppError(errorData));
		// }

		return Promise.reject(error);
	}
);

export default axios;