import React from 'react';
import './Pushdown.scss';

const Pushdown = (props) => {
    return (
        // <img src={bullhornIcon} alt='Notification' />
        <div className='pushdown' dangerouslySetInnerHTML={{
            __html: props.message
        }}></div>
    );
}

export default Pushdown;