import { combineReducers } from 'redux';
import stateReducer from './stateReducer';
import miscReducer from './miscReducer';
import coursesReducer from './coursesReducer';
import speakersReducer from './speakersReducer';
import authReducer from './authReducer';
import productBundlesReducer from './productBundlesReducer';
import contentReducer from './contentReducer';
import memberReducer from './memberReducer';
import accountReducer from './accountReducer';

export default combineReducers({
  states: stateReducer,
  miscellaneous: miscReducer,
  courses: coursesReducer,
  speakers: speakersReducer,
  auth: authReducer,
  productBundles: productBundlesReducer,
  content: contentReducer,
  member: memberReducer,
  account: accountReducer
});