import {
    GET_MEMBER_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_COURSE_SUCCESS,
    ADD_PLAYLIST_COURSE_SUCCESS,
    GET_MEMBER_DEADLINES_SUCCESS,
    GET_COMPLETED_COURSES_SUCCESS,
    GET_MEMBER_COMPLIANCE_SUCCESS,
    GET_MEMBER_PERIODS_SUCCESS,
    MOVE_MEMBER_CREDITS_SUCCESS,
    GET_MEMBER_TRANSACTIONS_SUCCESS,
    CLEAR_MEMBER,
    SET_MEMBER_RECEIPT,
    GET_DEADLINE_CONFIG_SUCCESS,
    GET_MEMBER_REFERRAL_SUCCESS,
    GET_MEMBER_NOTIFICATIONS,
    CHECK_MEMBER_LIVE_COURSE    
} from '../actions/types';

const initialState = {
    memberPlaylist: '',
    memberDeadlines: {},
    memberPeriods: [],
    completedCourses: {},
    compliance: [],
    transactions: [],
    memberReceipt: {},
    deadlineConfig: {},
    referral: null,
    notifications: {},
    liveCourse: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MEMBER_PLAYLIST_SUCCESS:
            {
                return {
                    ...state,
                    memberPlaylist: action.payload
                }
            }
        case DELETE_PLAYLIST_COURSE_SUCCESS:
            {
                return {
                    ...state,
                    memberPlaylist: action.payload
                }
            }
        case ADD_PLAYLIST_COURSE_SUCCESS:
            {
                return {
                    ...state,
                    memberPlaylist: action.payload
                }
            }
        case GET_MEMBER_DEADLINES_SUCCESS:
            {
                return {
                    ...state,
                    memberDeadlines: action.payload
                }
            }
        case GET_COMPLETED_COURSES_SUCCESS:
            {
                return {
                    ...state,
                    completedCourses: action.payload
                }
            }
        case GET_MEMBER_COMPLIANCE_SUCCESS:
            {
                return {
                    ...state,
                    compliance: action.payload
                }
            }
        case GET_MEMBER_PERIODS_SUCCESS:
            {
                return {
                    ...state,
                    memberPeriods: action.payload
                }
            }
        case MOVE_MEMBER_CREDITS_SUCCESS:
            {
                return {
                    ...state,
                    completedCourses: action.payload
                }
            }
        case GET_MEMBER_TRANSACTIONS_SUCCESS:
            {
                return {
                    ...state,
                    transactions: action.payload
                }
            }
        case CLEAR_MEMBER:
            {
                return initialState;
            }
        case SET_MEMBER_RECEIPT:
            {
                return {
                    ...state,
                    memberReceipt: action.payload
                }
            }
        case GET_DEADLINE_CONFIG_SUCCESS:
            {
                return {
                    ...state,
                    deadlineConfig: action.payload
                }
            }
        case GET_MEMBER_REFERRAL_SUCCESS:
            {
                return {
                    ...state,
                    referral: action.payload
                }
            }
        case GET_MEMBER_NOTIFICATIONS:
            {
                return {
                    ...state,
                    notifications: action.payload
                }
            }
        case CHECK_MEMBER_LIVE_COURSE:
        {
            return {
                ...state,
                liveCourse: action.payload
            }
        }
        default:
            return state;
    }
}