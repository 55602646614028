import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const rackspaceURL = process.env.REACT_APP_RACKSPACE_URL;
// images
const Logo = 'images/LexVidLogoWhite.png';

const NavbarBrand = (props) => {

    const imageMetaData = useSelector(state => state.content.imageMetaData);

    return <Navbar.Brand className='header-logo'>
        <Link to={props.homeLink} aria-label="Home">
            <img alt={imageMetaData && imageMetaData[Logo]?.AltTag} width='154' height='37' src={`${rackspaceURL}/${Logo}`} />
        </Link>
    </Navbar.Brand>

}

export default NavbarBrand;
