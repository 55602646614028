import {
    GET_BUNDLE_CONTENT, 
    GET_BUNDLES, 
    GET_PACKAGES_CONTENT, 
    UPDATE_SELECTED_BUNDLE, 
    UPDATE_PURCHASED_BUNDLE, 
    GET_PACKAGES_PRICING,
    UPDATE_SELECTED_LIVE_EVENTS,
    SET_INCLUDE_SELECTED_LIVE_EVENTS,
    SET_CART_ID,
    REMOVE_FROM_SELECTED_LIVE_EVENTS,
    UPDATE_IS_LIVE_COUPON_BAD,
    SET_LIVE_CART,
    REMOVE_FROM_LIVE_CART,
    ADD_TO_TEMP_CART,
    CLEAR_TEMP_CART,
    SET_COURSES_SELECTED_CART,
    SET_IS_FRACTIONAL_PRICE,
    UPDATE_SELECTED_BUNDLE_2,
    SET_SELECTED_FREE_LIVE_EVENT_IDS,
    SET_ONLY_FREE_LIVE_EVENTS,
} from '../actions/types';

const initialState = {
    bundleContent: {},
    bundles: [],
    packagesContent: {},
    packagesPricing: {},
    selectedBundle: {},
    selectedBundle2: {},
    purchasedBundle: {},
    selectedLiveEvents: [],
    includeSelectedLiveEvents: false, 
    cartId: null,
    isLiveCouponBad: false,
    liveCart: [],
    tempCart: [],
    coursesSelectedCart: [],
    isFractionalPrice: false,
    selectedFreeEventIds: null,
    isOnlyFreeLiveEvents: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BUNDLE_CONTENT:
            {
                return {
                    ...state,
                    bundleContent: action.payload
                }
            }
        case GET_BUNDLES:
            {
                return {
                    ...state,
                    bundles: action.payload
                }
            }
        case GET_PACKAGES_CONTENT:
            {
                return {
                    ...state,
                    packagesContent: action.payload
                }
            }
        case UPDATE_SELECTED_BUNDLE: 
            {
                return {
                    ...state, 
                    selectedBundle: action.payload
                }
            }
        case UPDATE_SELECTED_BUNDLE_2: 
        {
            return {
                ...state, 
                selectedBundle2: action.payload
            }
        }
        case UPDATE_PURCHASED_BUNDLE: 
            {
                return {
                    ...state, 
                    purchasedBundle: action.payload
                }
            }
        case GET_PACKAGES_PRICING: 
            {
                return {
                    ...state, 
                    packagesPricing: action.payload
                }
            }
        case UPDATE_SELECTED_LIVE_EVENTS:
            {
                return {
                    ...state,
                    selectedLiveEvents: action.payload
                }
            }
        case REMOVE_FROM_SELECTED_LIVE_EVENTS:
            {
                return {
                    ...state,
                    selectedLiveEvents: state.selectedLiveEvents.filter(liveEvent => liveEvent.LiveEventsID !== action.payload)
                }
            }
        case SET_INCLUDE_SELECTED_LIVE_EVENTS:
        {
            return {
                ...state,
                includeSelectedLiveEvents: action.payload
            }
        }
            
        case SET_CART_ID:
            {
                return {
                    ...state,
                    cartId: action.payload
                }
            }
        case UPDATE_IS_LIVE_COUPON_BAD:
            {
                return {
                    ...state,
                    isLiveCouponBad: action.payload
                }
            }
        case SET_LIVE_CART:
            {
                return {
                    ...state,
                    liveCart: action.payload
                }
            }
        case REMOVE_FROM_LIVE_CART:
            {
                return {
                    ...state,
                    liveCart: state.liveCart.filter(liveEvent => liveEvent.LiveEventsID !== action.payload)
                }
            }
        case ADD_TO_TEMP_CART:
            {
                if (state.tempCart.find(c => c.LiveEventsID === action.payload.LiveEventsID)) { 
                    return state;
                } else {
                    const newCart = [...state.tempCart, action.payload];
                    return { ...state, tempCart: newCart }
                }
            }
        case CLEAR_TEMP_CART:
            {
                return {
                    ...state,
                    tempCart: []
                }
            }
        case SET_COURSES_SELECTED_CART:
            {
                return {
                    ...state,
                    coursesSelectedCart: action.payload
                }
            }
        case SET_IS_FRACTIONAL_PRICE:
            {
                return {
                    ...state,
                    isFractionalPrice: action.payload
                }
            }
        case SET_SELECTED_FREE_LIVE_EVENT_IDS:
        {
            return {
                ...state,
                selectedFreeEventIds: action.payload
            }
        }
        case SET_ONLY_FREE_LIVE_EVENTS:
        {
            return {
                ...state,
                isOnlyFreeLiveEvents: action.payload
            }
        }
        default:
            return state;
    }
}