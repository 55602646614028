import {
	GET_RECOMMENDED_COURSES_BY_ID,
	GET_POPULAR_STATE_COURSES,
	GET_NEWEST_STATE_COURSES,
	GET_SHORTEST_STATE_COURSES,
	GET_LONGEST_STATE_COURSES,
	GET_PRACTICE_AREAS_BY_ID,
	GET_COURSE_ACCREDITATION,
	GET_COURSE_LISTING_CONTENT,
	GET_COURSES,
	RESET_COURSES,
	GET_RECOMMENDED_COURSES_BY_URL,
	GET_PRACTICE_AREAS,
	GET_COURSE_DETAILS_START,
	GET_COURSE_DETAILS,
	GET_PLAYLIST_COURSE,
	GET_COURSE_SLIDES,
	GET_COURSE_FILES,
	GET_COURSE_NOTES,
	SET_COURSE_NOTES,
	SET_COURSE_TRACKING,
	CLEAR_COURSE,
	GET_COURSE_COMBINED_DATA,
	GET_COURSE_PROGRESS,
	GET_COURSE_BY_ID,
	GET_COURSES_COUNT,
	GET_COURSE_REVIEWS,
	SET_PREVIEW_COURSE,
	RESET_PREVIEW_COURSE,
	RESET_COURSE_LISTING_CONTENT,
	GET_COURSE_ID,
	SET_COMPLETED_COURSE_ID,
	GET_COURSE_REVIEWS_ALL,
	GET_LIVE_COURSES_HOURS
} from '../actions/types';
import { isEmpty } from 'lodash';

const initialState = {
	recommendedCourses: [],
	practiceAreas: [],
	courses: [],
	courseAccreditation: [],
	courseListingContent: {
		ContentBlock: {
			'On-Demand CLE Courses': {
				Content: '',
				Header: '',
				SubHeader: ''
			}
		}
	},
	coursesType: '',
	playlistCourse: {},
	course: {},
	isPreviewCourse: false,
	isLoading: false,
	loaded: false,
	isLastIndex: false,
	courseObject: {},
	courseEvaluation: {},
	courseMaxPositionFromCookie: 0,
	courseCurrentPositionFromCookie: 0,
	coursesPages: [],
	coursesCount: 0,
	reviews: [],
	reviewsAll: [],
	completedCourseId: null,
	liveCoursesHours: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_RECOMMENDED_COURSES_BY_ID:
			{
				return {
					...state,
					recommendedCourses: action.payload
				}
			}
		case GET_COURSES_COUNT:
			{
				return { ...state, coursesCount: action.payload }
			}
		case GET_POPULAR_STATE_COURSES:
			{
				if (state.coursesType === 'POPULAR') {
					return {
						...state,
						courses: state
							.courses
							.concat(action.payload.filter((item) => !state.courses.some(x => x.Course.ID === item.Course.ID))),
						//.concat(action.payload),
						isLoading: false,
						isLastIndex: isEmpty(action.payload) === true
							? true
							: false
					}
				} else {
					return {
						...state,
						courses: action.payload,
						coursesType: 'POPULAR',
						isLoading: false
					}
				}
			}
		case GET_NEWEST_STATE_COURSES:
			{
				if (state.coursesType === 'NEWEST') {
					return {
						...state,
						courses: state
							.courses
							.concat(action.payload),
						isLoading: false,
						isLastIndex: isEmpty(action.payload) === true
							? true
							: false
					}
				} else {
					return {
						...state,
						courses: action.payload,
						coursesType: 'NEWEST',
						isLoading: false
					}
				}
			}
		case GET_SHORTEST_STATE_COURSES:
			{
				if (state.coursesType === 'SHORTEST') {
					return {
						...state,
						courses: state
							.courses
							.concat(action.payload),
						isLoading: false,
						isLastIndex: isEmpty(action.payload) === true
							? true
							: false
					}
				} else {
					return {
						...state,
						courses: action.payload,
						coursesType: 'SHORTEST',
						isLoading: false
					}
				}
			}
		case GET_LONGEST_STATE_COURSES:
			{
				if (state.coursesType === 'LONGEST') {
					return {
						...state,
						courses: state
							.courses
							.concat(action.payload),
						isLoading: false,
						isLastIndex: isEmpty(action.payload) === true
							? true
							: false
					}
				} else {
					return {
						...state,
						courses: action.payload,
						coursesType: 'LONGEST',
						isLoading: false
					}
				}
			}
		case GET_PRACTICE_AREAS_BY_ID:
			{
				return {
					...state,
					practiceAreas: action.payload
				}
			}
		case GET_COURSE_ACCREDITATION:
			{
				return {
					...state,
					courseAccreditation: action.payload
				}
			}
		case GET_COURSES:
			{
				return {
					...state,
					isLoading: true
				}
			}
		case RESET_COURSES:
			{
				return {
					...state,
					coursesCount: 0,
					courses: [],
					recommendedCourses: []
				}
			}
		case GET_COURSE_LISTING_CONTENT:
			{
				return {
					...state,
					courseListingContent: action.payload
				}
			}
		case GET_RECOMMENDED_COURSES_BY_URL:
			{
				return {
					...state,
					recommendedCourses: action.payload
				}
			}
		case GET_PRACTICE_AREAS:
			{
				return {
					...state,
					practiceAreas: action.payload
				}
			}
		case CLEAR_COURSE:
			{
				return {
					...state,
					course: {}
				}
			}
		case GET_COURSE_DETAILS_START:
			{
				return {
					...state,
					isLoading: true,
					loaded: false,
					course: {}
				}
			}
		case GET_COURSE_ID:
			{
				return {
					...state,
					courseID: action.payload.ID
				}
			}
		case GET_COURSE_DETAILS:
			{
				return {
					...state,
					isLoading: false,
					loaded: true,
					course: { ...state.course, details: action.payload }
				}
			}
		case GET_PLAYLIST_COURSE:
			{
				return {
					...state,
					course: { ...state.course, playlistCourse: action.payload }
				}
			}
		case GET_COURSE_SLIDES:
			{
				return {
					...state,
					course: { ...state.course, slides: action.payload }
				}
			}
		case GET_COURSE_FILES:
			{
				return {
					...state,
					course: { ...state.course, files: action.payload }
				}
			}
		case GET_COURSE_NOTES:
			{
				return {
					...state,
					course: { ...state.course, notes: action.payload }
				}
			}
		case SET_COURSE_NOTES:
			{
				return {
					...state,
					course: { ...state.course, notes: action.payload }
				}
			}
		case SET_COURSE_TRACKING: {
			return {
				...state,
				course: { ...state.course, tracking: action.payload }
			}
		}
		case GET_COURSE_COMBINED_DATA: {
			return {
				...state,
				course: {
					...state.course,
					courseCurrentPositionFromCookie: action.payload.courseCurrentPositionFromCookie,
					courseMaxPositionFromCookie: action.payload.courseMaxPositionFromCookie,
					details: action.payload.details,
					files: action.payload.files,
					playlistCourse: action.payload.playlistCourse,
					recommendedCourses: action.payload.recommendedCourses,
					slides: action.payload.slides
				},
				isLoading: false,
				loaded: true,
			}
		}
		case GET_COURSE_PROGRESS:
			{
				return {
					...state,
					courseEvaluation: action.payload
				}
			}
		case GET_COURSE_BY_ID:
			{
				return {
					...state,
					courseObject: action.payload
				}
			}
		case GET_COURSE_REVIEWS:
			{
				return {
					...state,
					reviews: action.payload
				}
			}
		case GET_COURSE_REVIEWS_ALL:
			{
				return {
					...state,
					reviewsAll: action.payload
				}
			}
		case SET_PREVIEW_COURSE:
			{
				return {
					...state,
					isPreviewCourse: true
				}
			}
		case RESET_PREVIEW_COURSE:
			{
				return {
					...state,
					isPreviewCourse: false
				}
			}
		case RESET_COURSE_LISTING_CONTENT:
			{
				return {
					...state,
					courseListingContent: {
						ContentBlock: {
							'On-Demand CLE Courses': {
								Content: '',
								Header: '',
								SubHeader: ''
							}
						}
					}
				}
			}
		case SET_COMPLETED_COURSE_ID:
			{
				return {
					...state,
					completedCourseId: action.payload
				}
			}
		case GET_LIVE_COURSES_HOURS:
			{
				return {
					...state,
					liveCoursesHours: action.payload
				}
			}
		default:
			return state
	}
}