import {
	GET_MEMBER_PLAYLIST,
	GET_MEMBER_PLAYLIST_SUCCESS,
	DELETE_PLAYLIST_COURSE,
	DELETE_PLAYLIST_COURSE_SUCCESS,
	ADD_PLAYLIST_COURSE,
	ADD_PLAYLIST_COURSE_SUCCESS,
	GET_MEMBER_DEADLINES,
	GET_MEMBER_DEADLINES_SUCCESS,
	GET_COMPLETED_COURSES,
	GET_COMPLETED_COURSES_SUCCESS,
	GET_MEMBER_COMPLIANCE,
	GET_MEMBER_COMPLIANCE_SUCCESS,
	GET_MEMBER_PERIODS,
	GET_MEMBER_PERIODS_SUCCESS,
	MOVE_MEMBER_CREDITS,
	MOVE_MEMBER_CREDITS_SUCCESS,
	GET_MEMBER_TRANSACTIONS,
	GET_MEMBER_TRANSACTIONS_SUCCESS,
	ADD_MEMBER_PRACTICE_AREA,
	ADD_MEMBER_PRACTICE_AREA_SUCCESS,
	DELETE_MEMBER_PRACTICE_AREA,
	DELETE_MEMBER_PRACTICE_AREA_SUCCESS,
	EDIT_MEMBER_SHALLOW,
	EDIT_MEMBER_SHALLOW_SUCCESS,
	EDIT_MEMBER_CONTACT,
	EDIT_MEMBER_CONTACT_SUCCESS,
	UPDATE_MEMBER_BAR,
	UPDATE_MEMBER_BAR_SUCCESS,
	DELETE_MEMBER_BAR,
	DELETE_MEMBER_BAR_SUCCESS,
	SET_MEMBER_RECEIPT,
	GET_DEADLINE_CONFIG,
	GET_DEADLINE_CONFIG_SUCCESS,
	GET_MEMBER_REFERRAL,
	GET_MEMBER_REFERRAL_SUCCESS,
	GET_MEMBER_NOTIFICATIONS,
	MEMBER_PAGE_REQUEST,
	CHECK_MEMBER_LIVE_COURSE
} from './types';
import MemberAPI from '../api/MemberAPI';
import { setUser } from './authActions';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

const token = Cookies.get('lexvid_token');

export const getMemberPlaylist = () => dispatch => {
	dispatch({ type: GET_MEMBER_PLAYLIST });

	MemberAPI
		.getMemberPlaylist()
		.then((memberPlaylist) => {
			dispatch({ type: GET_MEMBER_PLAYLIST_SUCCESS, payload: memberPlaylist })
		})
		.catch((error) => {
			console.log(error);
		})
}

export const deleteMemberPlaylistCourse = (courseID) => dispatch => {
	dispatch({ type: DELETE_PLAYLIST_COURSE });

	return new Promise((resolve, reject) => {
		MemberAPI
			.deleteMemberPlaylistCourse(courseID)
			.then((memberPlaylist) => {
				dispatch({ type: DELETE_PLAYLIST_COURSE_SUCCESS, payload: memberPlaylist });
			})
			.then(() => {
				dispatch(getMemberCompliance());
				resolve();
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}

export const removeFromPlaylist = (id) => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch({ type: DELETE_PLAYLIST_COURSE });
		MemberAPI
			.removeMemberPlaylistCourse(id)
			.then((memberPlaylist) => {
				dispatch({ type: DELETE_PLAYLIST_COURSE_SUCCESS, payload: memberPlaylist })
				resolve(id);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	});
}

export const addCourseToPlaylist = (courseID) => dispatch => {
	dispatch({ type: ADD_PLAYLIST_COURSE });

	return new Promise((resolve, reject) => {
		MemberAPI
			.addCourseToPlaylist(courseID)
			.then((memberPlaylist) => {
				dispatch({ type: ADD_PLAYLIST_COURSE_SUCCESS, payload: memberPlaylist });
			})
			.then(() => {
				dispatch(getMemberCompliance());
				resolve();
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	});
}

export const addToPlaylist = (id) => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch({ type: ADD_PLAYLIST_COURSE });

		MemberAPI
			.addCourseToPlaylist(id)
			.then((memberPlaylist) => {
				dispatch({ type: ADD_PLAYLIST_COURSE_SUCCESS, payload: memberPlaylist });
				resolve(id);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export const getMemberDeadlines = () => dispatch => {
	dispatch({ type: GET_MEMBER_DEADLINES });

	MemberAPI
		.getMemberDeadlines()
		.then((memberDeadlines) => {
			dispatch({ type: GET_MEMBER_DEADLINES_SUCCESS, payload: memberDeadlines });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const getCompletedCourses = (stateID, periodID) => dispatch => {
	dispatch({ type: GET_COMPLETED_COURSES });

	MemberAPI
		.getCompletedCourses(stateID, periodID)
		.then((completedCourses) => {
			dispatch({ type: GET_COMPLETED_COURSES_SUCCESS, payload: completedCourses });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const getMemberCompliance = () => dispatch => {
	dispatch({ type: GET_MEMBER_COMPLIANCE });

	MemberAPI
		.getMemberCompliance()
		.then((compliance) => {
			dispatch({ type: GET_MEMBER_COMPLIANCE_SUCCESS, payload: compliance });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const getMemberPeriods = (stateID) => dispatch => {
	dispatch({ type: GET_MEMBER_PERIODS });

	MemberAPI
		.getMemberPeriods(stateID)
		.then((memberPeriods) => {
			dispatch({ type: GET_MEMBER_PERIODS_SUCCESS, payload: memberPeriods });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const moveMemberCredits = (data) => dispatch => {
	dispatch({ type: MOVE_MEMBER_CREDITS });

	return new Promise((resolve, reject) => {
		MemberAPI
			.moveMemberCredits(data)
			.then((credits) => {
				dispatch({ type: MOVE_MEMBER_CREDITS_SUCCESS, payload: credits });
				resolve(credits);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
		});
}

export const getMemberTransactions = () => dispatch => {
	dispatch({ type: GET_MEMBER_TRANSACTIONS });

	MemberAPI
		.getMemberTransactions()
		.then((transactions) => {
			dispatch({ type: GET_MEMBER_TRANSACTIONS_SUCCESS, payload: transactions });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const addMemberPracticeArea = (practiceAreaID) => dispatch => {
	dispatch({ type: ADD_MEMBER_PRACTICE_AREA });

	MemberAPI
		.addMemberPracticeArea(practiceAreaID)
		.then((member) => {
			dispatch({ type: ADD_MEMBER_PRACTICE_AREA_SUCCESS });
			dispatch(setUser(member, token))
		})
		.catch((error) => {
			console.log(error);
		})
}

export const deleteMemberPracticeArea = (practiceAreaID) => dispatch => {
	dispatch({ type: DELETE_MEMBER_PRACTICE_AREA });

	MemberAPI
		.deleteMemberPracticeArea(practiceAreaID)
		.then((member) => {
			dispatch({ type: DELETE_MEMBER_PRACTICE_AREA_SUCCESS });
			dispatch(setUser(member, token));
		})
		.catch((error) => {
			console.log(error);
		})
}

export const editMemberShallow = (member) => dispatch => {
	dispatch({ type: EDIT_MEMBER_SHALLOW });

	MemberAPI
		.editMemberShallow(member)
		.then((member) => {
			dispatch({ type: EDIT_MEMBER_SHALLOW_SUCCESS });
			dispatch(setUser(member, token));
		})
		.catch((error) => {
			console.log(error);
		})
}

export const editMemberContact = (memberContact) => dispatch => {
	dispatch({ type: EDIT_MEMBER_CONTACT });

	MemberAPI
		.editMemberContact(memberContact)
		.then((member) => {
			dispatch({ type: EDIT_MEMBER_CONTACT_SUCCESS });
			dispatch(setUser(member, token, true));
		})
		.catch((error) => {
			console.log(error);
		})
}


export const updateMemberBar = (barData) => dispatch => {
	dispatch({ type: UPDATE_MEMBER_BAR });

	MemberAPI
		.updateMemberBar(barData)
		.then((barData) => {
			dispatch({ type: UPDATE_MEMBER_BAR_SUCCESS });
			dispatch(setUser(barData, token));
		}).catch((error) => {
			console.log(error);
		})
}

export const deleteMemberBar = (barID) => dispatch => {
	dispatch({ type: DELETE_MEMBER_BAR });

	MemberAPI
		.deleteMemberBar(barID)
		.then((memberBar) => {
			dispatch({ type: DELETE_MEMBER_BAR_SUCCESS });
			dispatch(setUser(memberBar, token));
		}).catch((error) => {
			console.log(error);
		})

}

export const setMemberReceipt = (memberReceipt) => dispatch => {
	dispatch({ type: SET_MEMBER_RECEIPT, payload: memberReceipt });
}

export const getDeadlineConfig = (stateID) => dispatch => {
	dispatch({ type: GET_DEADLINE_CONFIG });

	return new Promise((resolve, reject) => {
		MemberAPI
			.getDeadlineConfig(stateID)
			.then((deadlineConfig) => {
				console.log('ACTION', deadlineConfig);
				dispatch({ type: GET_DEADLINE_CONFIG_SUCCESS, payload: deadlineConfig });
				resolve(deadlineConfig);
			}).catch((error) => {
				console.log(error);
				reject(error);
			})
	})

}

export const getMemberReferral = () => dispatch => {
	dispatch({ type: GET_MEMBER_REFERRAL });

	MemberAPI
		.getMemberReferral()
		.then((data) => {
			dispatch({ type: GET_MEMBER_REFERRAL_SUCCESS, payload: data });
		}).catch((error) => {
			console.log(error);
		})
}


export const getMemberNotifications = () => dispatch => {
	MemberAPI
		.getMemberNotifications()
		.then((data) => {
			dispatch({ type: GET_MEMBER_NOTIFICATIONS, payload: data });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const deleteMemberNotification = (id, stateID) => dispatch => {
	MemberAPI
		.deleteMemberNotification(id, stateID)
		.then((data) => {
			dispatch({ type: GET_MEMBER_NOTIFICATIONS, payload: data });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const trackMemberPageRequest = (pageURL) => dispatch => {
	dispatch({ type: MEMBER_PAGE_REQUEST });

	MemberAPI
		.trackMemberPageRequest(pageURL)
		.then((response) => {

		});
}

export const createOutsideCredits = (credits) => dispatch => {
	return new Promise((resolve, reject) => {
		try {
			MemberAPI
				.createOutsideCredits(credits)
				.then((response) => {
					dispatch({ type: GET_COMPLETED_COURSES_SUCCESS, payload: response });

					resolve(response);
				});
		} catch (error) {
			reject(error);
		}
	});
}

export const deleteOutsideCredits = (ids) => async dispatch => {
	return new Promise((resolve, reject) => {
		Promise.all(
			ids.map(async (id) => {
				const response = await MemberAPI.deleteOutsideCredit(id);
				return response;
			})
		).then((responses) => {
			// return last response
			const response = isEmpty(responses) ? null : responses[responses.length - 1];

			dispatch({ type: GET_COMPLETED_COURSES_SUCCESS, payload: response });
			resolve(response);

		}).catch((error) => {
			console.log(error);
			reject();
		});
	});
}

export const checkLiveCourse = (memberId) => dispatch => {
	MemberAPI
		.checkLiveCourse(memberId)
		.then((data) => {
			if (!data || data === "No Courses today")
				dispatch({ type: CHECK_MEMBER_LIVE_COURSE, payload: null });
			else
				dispatch({ type: CHECK_MEMBER_LIVE_COURSE, payload: data });
		})
		.catch((error) => {
			console.log(error);
		})
}
