import React from 'react';
import './App.css';
import Header from '../Header';
import Footer from '../Footer';
import Routes from '../../Routes';
import useResizeListener from "../../hooks/useResizeListener";
import useRestoreUser from "../../hooks/useRestoreUser";
// import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../common/ErrorFallback';
import * as Sentry from "@sentry/react";

function App() {

	useRestoreUser();
	useResizeListener();

	const errorFallback = (props) => <ErrorFallback {...props} />;
	
	return (
		<Sentry.ErrorBoundary fallback={(props) => errorFallback(props)} >
			<Header />
			<div className='view-container'>
				<Routes />
			</div>
			<Footer />
		</Sentry.ErrorBoundary>
	);

}

export default App;