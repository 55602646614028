import {
    GET_SPEAKERS,
    GET_SPEAKERS_SHALLOW,
    SEARCH_SPEAKERS,
    UPDATE_SELECTED_SPEAKER_URL,
    GET_SPEAKER_BY_URL,
    GET_SPEAKER_COURSES_BY_URL,
    GET_SPEAKER_REVIEWS,
    GET_SPEAKER_TESTIMONIALS,
    // SUBMIT_SPEAKER_QUESTION_SUCCESS,
} from '../actions/types';

const initialState = {
    speaker: {
        IconURL: '',
        Name: '',
        Location: '',
        Phone: '',
        Email: '',
        BarMembership: '',
        PracticeAreas: '',
        Biography: '',
        ProfessionalLicense: '',
        Education: '',
        AcademicAffilations: '',
        BusinessAffilations: '',
        Awards: ''
    },
    speakers: [],
    speakersShallow: [],
    selectedSpeakerURL: '',
    speakerCourses: [],
    speakerReviews: [],
    speakerTestimonials: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SPEAKERS:
            {
                return {
                    ...state,
                    speakers: action.payload
                }
            }
        case GET_SPEAKERS_SHALLOW:
            {
                return {
                    ...state,
                    speakersShallow: action.payload
                }
            }
        case SEARCH_SPEAKERS:
            {
                return {
                    ...state,
                    speakers: action.payload
                }
            }
        case UPDATE_SELECTED_SPEAKER_URL:
            {
                return {
                    ...state,
                    selectedSpeakerURL: action.payload
                }
            }
        case GET_SPEAKER_BY_URL:
            {
                return {
                    ...state,
                    speaker: action.payload
                }
            }
        case GET_SPEAKER_COURSES_BY_URL:
            {
                return {
                    ...state,
                    speakerCourses: action.payload
                }
            }
        case GET_SPEAKER_REVIEWS:
            {
                return {
                    ...state,
                    speakerReviews: action.payload
                }
            }
        case GET_SPEAKER_TESTIMONIALS:
            {
                return {
                    ...state,
                    speakerTestimonials: action.payload
                }
            }
        // case SUBMIT_SPEAKER_QUESTION_SUCCESS:
        //     { 
        //         return {
        //             ...state,

        //         }
        //     }
        default:
            return state;
    }
}