import React from 'react';
import './ErrorFallback.scss';
import errorRobot from '../../../assets/images/error-500.png';
import { Button } from 'react-bootstrap';
import useFooterFixed from '../../../hooks/useFooterFixed';
// import store from '../../../store';
// import { logAppError } from '../../../actions/miscActions';
// import { useDispatch } from 'react-redux';


const ErrorFallback = ({componentStack, error, eventId, resetError}) => {

	// const dispatch = useDispatch();
	useFooterFixed(1200);

	// const error = props.error;

	// console.log('---- props.error from fallback component: ')
	// console.log(props.error);

	// let errorData = {
	// 	ErrorMessage: error.message,
	// 	// Exception: eventError,
	// 	StackTrace: error.stack,
	// 	PageName: window.location.pathname, // TODO: need to get component name
	// 	FullURL: window.location.href,
	// 	InternalContext: JSON.stringify(store.getState())
	// };

	// console.log('---- errorData from fallback component: ')
	// console.log(errorData);

	// dispatch(logAppError(errorData));


	const handleReload = () => {
		// props.resetErrorBoundary();
		resetError();
		window.location.reload(false);
	}

	return <div className='error-fallback-container container'>

		<div>
			<h1>There Was a Problem Processing Your Request!</h1>

			<div>
				{`> Error: ${error?.message ? error.message : error?.CustomMessage}`}
				<br />
				{`> Event ID: ${eventId}`}
			</div>
			{/* <div>
				{props.error?.response?.data?.ExceptionMessage}
				<br /><br />
				{props.error?.response?.data?.StackTrace}
			</div> */}

			<br /><br />

			<div className='font-weight-bold'>Not to worry, let's give this another try! Click the button below to try again.</div>

			<div className='try-again-wrap'>
				<Button variant='primary-blue' className='fal fa-redo try-again' onClick={handleReload}>Try again</Button>
			</div>


			<div className='info-wrap'>
				<div>
					If you have already clicked "Try Again" and have received this message a second time, please let us know about your problem so we can address it ASAP.
				</div>

				<div>
					{`> `}<a href='https://help.lexvid.com/conversation/new' target='_blank' rel='noopener noreferrer'>Contact Customer Support</a>
				</div>
				<div>
					{`> `}<a href='https://help.lexvid.com' target='_blank' rel='noopener noreferrer'>Visit help.lexvid.com</a>
				</div>
				<div>
					{`> `}<a href='tel:1-877-327-1226,1' target='_blank' rel='noopener noreferrer'>Call 1-877-327-1226 Ex 1</a>
				</div>
			</div>
		</div>

		<img className='error-robot' src={errorRobot} alt="error robot" />

	</div>;
}

export default ErrorFallback;