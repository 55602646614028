import React, { useEffect, useRef, useState } from 'react';
import { Nav, Navbar, NavDropdown, DropdownButton, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logout } from '../../actions/authActions';
import NavbarBrand from './NavbarBrand';
import { isEmpty } from 'lodash';
import { HashLink } from 'react-router-hash-link';
import useUserHasBarWithLiveEvents from "../../hooks/useUserHasBarWithLiveEvents";
import useUserHasBarWithPaidState from "../../hooks/useUserHasBarWithPaidState";

const AuthenticatedMenu = (props) => {

	const homeLink = '/secure';

	const dispatch = useDispatch();
	const history = useHistory();

	const authState = useSelector(state => state.auth);

	const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
	const [menu1Shown, setMenu1Shown] = useState(false);
	const [menu2Shown, setMenu2Shown] = useState(false);
	const [userMenuShown, setUserMenuShown] = useState(false);
	const [homeMenuShown, setHomeMenuShown] = useState(false);
	const [myAccountMenuShown, setMyAccountMenuShown] = useState(false);
	const [completedMenuShown, setCompletedMenuShown] = useState(false);
	const [requirementsMenuShown, setRequirementsMenuShown] = useState(false);

	const user = authState ? (authState.user ? authState.user : null) : null;
	const userInitials = user ? `${user?.FirstName?.substring(0, 1)} ${user?.LastName?.substring(0, 1)}` : '';
	const userHasLiveEvents = !isEmpty(user?.UserLiveEvents);
	const userHasBarWithLiveEvents = useUserHasBarWithLiveEvents();
	const userHasBarWithPaidState = useUserHasBarWithPaidState();

	const collapseMobileMenu = () => setTimeout(() => { setMobileMenuExpanded(false) }, 150)

	const onLogoutClick = () => {
		dispatch(logout());
		history.push('/');
	}


	const filteredBars = function () {
		if (user?.Bars === null || user?.Bars?.length === 0)
			return null;


		var bars = user?.Bars?.filter(function (bar) {
			if (bar.State === null)
				return false;

			return !bar.State.HasNoCLERequirement;
		});


		return bars;
	}

	const stopClickPropagation = event => event.stopPropagation();

	const navigateToCourseListing = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const newUrl = '/secure/courses?pageNumber=1&sortBy=popular';
		history.push('/loading');
		setTimeout(() => {
			history.replace(newUrl)
		}, 100);
	}

	const navigateToPracticeAreaCourses = (event, practiceUrl) => {
		event.preventDefault();
		event.stopPropagation();

		const newUrl = `/secure/courses/${practiceUrl}?pageNumber=1&sortBy=popular`;

		history.push('/loading');
		setTimeout(() => {
			history.replace(newUrl)
		}, 100);

	}

	// Use same rules that determine whether to hide the bundle tile to determine whether to hide the bundle from the global navigation sub list.
	const userHasMoreThanOneBundle = user?.Bundles?.length > 1;
	const allBundlesComplete = user?.Bundles?.reduce((acc, current, index) => acc && current.OutstandingBundleHours === 0, true);
	const userBundles = user?.Bundles;
	// console.log(userBundles);
	const bundlesToShow = useRef();
	useEffect(() => {

		if (userHasMoreThanOneBundle && allBundlesComplete) bundlesToShow.current = userBundles;
		else bundlesToShow.current = userBundles?.filter(bundle => bundle.OutstandingBundleHours > 0);

	}, [userBundles, allBundlesComplete, userHasMoreThanOneBundle])

	const homeSubMenuClick = (event) => {
		setHomeMenuShown(!homeMenuShown);
		stopClickPropagation(event);
	}

	const completedSubMenuClick = (event) => {
		setCompletedMenuShown(!completedMenuShown);
		stopClickPropagation(event);
	}

	const requirementsSubMenuClick = (event) => {
		setRequirementsMenuShown(!requirementsMenuShown);
		stopClickPropagation(event);
	}

	const myAccountSubMenuClick = (event) => {
		setMyAccountMenuShown(!myAccountMenuShown);
		stopClickPropagation(event);
	}

	const navigateToLiveCourses = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const newUrl = `/secure/packages/live`;

		history.push('/loading');
		setTimeout(() => {
			history.replace(newUrl)
		}, 100);
	}


	return <Navbar expand='md' variant='light' className='header-nav navbar-auth' onToggle={(expanded) => setMobileMenuExpanded(expanded)} expanded={mobileMenuExpanded}>

		<NavbarBrand homeLink={homeLink} />

		<Navbar.Toggle className='d-flex d-md-none d-lg-none'>
			<span>MENU</span> {mobileMenuExpanded ? <i className="fas fa-caret-up fa-open-menu"></i> : <i className="fas fa-caret-down fa-open-menu"></i>}
		</Navbar.Toggle>


		<Navbar.Collapse className='responsive-navbar-nav'>

			<Nav className='mr-auto navbar d-none d-md-flex dt-links-menu'>

				<NavDropdown title='Home' className='my-courses-and-certificates'
					onMouseEnter={() => setMenu1Shown(true)} onMouseLeave={() => setMenu1Shown(false)} show={menu1Shown}
					onClick={() => { history.push('/secure') }}>

					{bundlesToShow.current && bundlesToShow.current?.map((bundle, index) => (
						<NavDropdown.Item
							key={index}
							as={Link}
							to={`/secure/bundle/${bundle?.ID}`}
							onClick={stopClickPropagation}
						>
							<div className='arrow-right'></div> {bundle.Name}
						</NavDropdown.Item>
					))}

					<NavDropdown.Item
						as={Link}
						to={'/secure/playlist'}
						onClick={stopClickPropagation}
					>
						<div className='arrow-right'></div> Custom Playlist
					</NavDropdown.Item>

					{userHasLiveEvents ?
						<NavDropdown.Item
							as={Link}
							to={`/secure/live`}
							onClick={stopClickPropagation}
						>
							<div className='arrow-right'></div> My Live CLE Courses
						</NavDropdown.Item> : null
					}

					{user?.Bars?.length === 1 &&
						<a href={`/secure/completed/${user?.Bars[0]?.State?.FriendlyURL}`} className='dropdown-item'>
							<div className='arrow-right'></div>Completed Courses & Certificates
						</a>
					}

					{/* For multistate members, completed courses will have a submenu for each bar state. 
                                    clicking on a specific will direct to /secure/completed/[state-url] */}
					{user?.Bars?.length > 1 &&
						<div className="dropdown-submenu">
							<a className="dropdown-item" href={`/secure/completed/${user?.Bars[0]?.State?.FriendlyURL}`}>
								<div className='arrow-right'></div> Completed Courses & Certificates
							</a>
							<ul className="dropdown-menu">
								{user?.Bars?.map((bar, index) => (
									<NavDropdown.Item key={index} href={`/secure/completed/${bar.State?.FriendlyURL}`}>
										<div className='arrow-right'></div> {bar.State?.Name}
									</NavDropdown.Item>
								))}
							</ul>
						</div>
					}

					{user?.Bars?.length === 1 &&
						<a className="dropdown-item" href={`/secure/requirements/${user?.Bars[0]?.State?.FriendlyURL}`}>
							<div className='arrow-right'></div>State Requirements & Reporting
						</a>
					}
					{/* For multistate members, requirements will have a submenu for each bar state. 
                                    For single state, it will just be the requirements link */}
					{user?.Bars?.length > 1 &&
						<div className="dropdown-submenu">
							<a className="dropdown-item" href={`/secure/requirements/${user?.Bars[0]?.State?.FriendlyURL}`}>
								<div className='arrow-right'></div> State Requirements & Reporting
							</a>
							<ul className="dropdown-menu">
								{filteredBars()?.map((bar, index) => (
									<NavDropdown.Item key={index} href={`/secure/requirements/${bar.State?.FriendlyURL}`}>
										<div className='arrow-right'></div> {bar.State?.Name}
									</NavDropdown.Item>
								))}
							</ul>
						</div>
					}
				</NavDropdown>

				<NavDropdown
					title='Find CLE Courses'
					className='find-cle-courses'
					onMouseEnter={() => setMenu2Shown(true)}
					onMouseLeave={() => setMenu2Shown(false)}
					onClick={(event) => navigateToCourseListing(event)}
					show={menu2Shown}
				>
					<div className="dropdown-submenu">
						<NavDropdown.Item className="dropdown-item" onClick={(event) => event.stopPropagation()}>
							<div className='arrow-right'></div> Favorite Practice Areas
						</NavDropdown.Item>
						<ul className="dropdown-menu">
							{user?.MyPracticeAreas && user?.MyPracticeAreas?.map((area, index) => (
								<NavDropdown.Item key={index} onClick={(event) => navigateToPracticeAreaCourses(event, area.FriendlyURL)} href={''}>
									<div className='arrow-right'></div> {area.Name}
								</NavDropdown.Item>
							))}

							<HashLink to='/secure/my-account#PracticeAreas' className='dropdown-item'>
								<div className='arrow-right'></div> {isEmpty(user?.MyPracticeAreas) ? 'Add' : 'Manage'} Favorite Practice Areas
							</HashLink>

						</ul>
					</div>
					<div className="dropdown-submenu">
						<a className="dropdown-item" href="/secure/courses">
							<div className='arrow-right'></div> Popular Practice Areas
						</a>
						<ul className="dropdown-menu">
							{user?.PopularPracticeAreas && user?.PopularPracticeAreas?.map((area, index) => (
								<NavDropdown.Item key={index} onClick={(event) => navigateToPracticeAreaCourses(event, area.FriendlyURL)} href={''}>
									<div className='arrow-right'></div> {area.Name}
								</NavDropdown.Item>
							))}
						</ul>
					</div>
					<div className="dropdown-submenu">
						<a className="dropdown-item" href="/secure/courses">
							<div className='arrow-right'></div> All Practice Areas
						</a>
						<ul className="dropdown-menu">
							{user?.AllPracticeAreas && user?.AllPracticeAreas?.map((area, index) => (
								<NavDropdown.Item key={index} onClick={(event) => navigateToPracticeAreaCourses(event, area.FriendlyURL)} href={''}>
									<div className='arrow-right'></div> {area.Name}
								</NavDropdown.Item>
							))}
						</ul>
					</div>

					{userHasBarWithLiveEvents ? 
						<NavDropdown.Item onClick={(event) => navigateToLiveCourses(event)}>
							<div className='arrow-right'></div> Live CLE Courses
						</NavDropdown.Item> : null			
					}
				</NavDropdown>

				{userHasBarWithPaidState ? 
					<Link className='header-item packages-and-pricing' to={user?.Bars?.length > 1 ? '/secure/multi-state-cle/packages' : '/secure/cle-packages'}>Packages & Pricing</Link> : null }
					
			</Nav>

			<style dangerouslySetInnerHTML={{
				__html: [
					'.user-menu-button .dropdown-toggle:before {',
					`  content: "${userInitials.replace(' ', '')}";`,
					'}',
					'.user-menu-button .dropdown-toggle:after {',
					`  content: "${user?.FirstName} ${user?.LastName?.substring(0, 1)}";`,
					'}',
					'.navbar-toggler:after {',
					`  content: "${user?.FirstName} ${user?.LastName?.substring(0, 1)}";`,
					'}',
				].join('\n')
			}}>
			</style>

			<DropdownButton alignRight title=''
				className='user-menu-button d-none d-md-block'
				onClick={() => history.push('/secure/my-account')}
				onMouseEnter={() => setUserMenuShown(true)}
				onMouseLeave={() => setUserMenuShown(false)}
				show={userMenuShown}>
				<Dropdown.Item className='user-name-email' href='/secure/my-account'>
					<div className='circle-initials'>
						{userInitials}
					</div>
					<div>
						<div>{user?.FirstName} {user?.LastName}</div>
						<div>{user?.Email}</div>
					</div>
				</Dropdown.Item>
				<HashLink to='/secure/my-account#PracticeAreas' onClick={stopClickPropagation} className='dropdown-item'>
					<div className='arrow-right'></div> Manage Favorite Practice Areas
				</HashLink>
				<HashLink to='/secure/my-account#BarInfo' onClick={stopClickPropagation} className='dropdown-item'>
					<div className='arrow-right'></div> Manage Bar Information
				</HashLink>
				<HashLink to='/secure/my-account#PurchaseHistory' onClick={stopClickPropagation} className='dropdown-item'>
					<div className='arrow-right'></div> View Purchase History
				</HashLink>
				<Link to='/secure/cle-referral-program' onClick={stopClickPropagation} className='dropdown-item'>
					<div className='arrow-right'></div> Refer a Friend - Earn $20
				</Link>

				<a onClick={onLogoutClick} href='/' className='dropdown-item logout-button'>Log Out</a>

				<a className='dropdown-item' href='https://help.lexvid.com/' target='_blank' rel='noopener noreferrer'>Help</a>

				<a className='dropdown-item justify-content-center d-flex' href='tel:1-877-327-1226'>Call 1-877-327-1226</a>

			</DropdownButton>

			<Nav className='mobile-menu d-flex d-md-none d-lg-none'>

				<div className='user-name-email'>
					<div className='circle-initials'>
						{userInitials}
					</div>
					<div>
						<div>{user?.FirstName} {user?.LastName}</div>
						<div>{user?.Email}</div>
					</div>
				</div>

				<NavDropdown
					title={<div className='mm-item'>
						<div className={homeMenuShown ? 'arrow-right arrow-down' : 'arrow-right'}></div> Home
					</div>}
					onClick={event => homeSubMenuClick(event)}
					show={homeMenuShown}
					onMouseEnter={() => setHomeMenuShown(true)}
					onMouseLeave={() => setHomeMenuShown(false)}>

					<div className="mm-dropdown-submenu">

						{/* link at top called "Home Page" that links to /secure */}
						<Link to={'/secure'} className='mm-item' onClick={collapseMobileMenu}>
							<div className='arrow-right'></div> Home Page
						</Link>

						{/* links to active bundles to the top of the home sub section */}
						{/* {bundlesToShow.current && bundlesToShow.current.map((bundle, index) => ( */}
						{bundlesToShow.current && bundlesToShow.current?.map((bundle, index) => (
							<Link key={index} to={`/secure/bundle/${bundle?.ID}`} onClick={collapseMobileMenu} className='mm-item'>
								<div className='arrow-right'></div> {bundle.Name}
							</Link>
						))}

						<Link to='/secure/playlist' className='mm-item' onClick={collapseMobileMenu}>
							<div className='arrow-right'></div> Custom Playlist
						</Link>

						{/* <a className='mm-item' href={`/secure/completed/${user?.Bars && user?.Bars.length > 0 ? user?.Bars[0]?.State?.FriendlyURL : ''}`} onClick={collapseMobileMenu}>
							<div className='arrow-right'></div> Completed Courses & Certificates
						</a> */}
						{user?.Bars?.length === 1 ?
							<a className='mm-item' href={`/secure/completed/${user?.Bars[0]?.State?.FriendlyURL}`} onClick={collapseMobileMenu}>
								<div className='arrow-right'></div> Completed Courses & Certificates
							</a>
							:
							<NavDropdown
								title={<div className='mm-item'>
									<div className={completedMenuShown ? 'arrow-right arrow-down' : 'arrow-right'}></div> Completed Courses & Certificates
								</div>}
								onClick={event => completedSubMenuClick(event)}
								show={completedMenuShown}
								onMouseEnter={() => setCompletedMenuShown(true)}
								onMouseLeave={() => setCompletedMenuShown(false)}
							>
								{!isEmpty(user?.Bars) ?
									<div className="mm-dropdown-submenu">
										{user?.Bars?.map((bar, index) => (
											<a className='mm-item' key={index} href={`/secure/completed/${bar.State?.FriendlyURL}`} onClick={collapseMobileMenu}>
												<div className='arrow-right'></div> {bar.State?.Name}
											</a>
										))}
									</div>
									: null}
							</NavDropdown>
						}

						{/* <Link to='/secure/requirements' className='mm-item'>
							<div className='arrow-right'></div> State Requirements & Reporting
						</Link> */}
						{user?.Bars?.length === 1 ?
							<a className='mm-item' href={`/secure/requirements/${user?.Bars[0]?.State?.FriendlyURL}`} onClick={collapseMobileMenu}>
								<div className='arrow-right'></div> State Requirements
							</a>
							:
							<NavDropdown
								title={<div className='mm-item'>
									<div className={requirementsMenuShown ? 'arrow-right arrow-down' : 'arrow-right'}></div> State Requirements
								</div>}
								onClick={event => requirementsSubMenuClick(event)}
								show={requirementsMenuShown}
								onMouseEnter={() => setRequirementsMenuShown(true)}
								onMouseLeave={() => setRequirementsMenuShown(false)}
							>
								<div className="mm-dropdown-submenu">
									{filteredBars()?.map((bar, index) => (
										<NavDropdown.Item key={index} href={`/secure/requirements/${bar.State?.FriendlyURL}`}>
											<div className='arrow-right'></div> {bar.State?.Name}
										</NavDropdown.Item>
									))}
								</div>
							</NavDropdown>
						}

					</div>

				</NavDropdown>

				<Link to={`/secure/courses`} onClick={collapseMobileMenu} className='mm-item'>
					<div className='arrow-right'></div> Find CLE Courses
				</Link>

				{userHasBarWithPaidState ? 
					<Link to={`/secure/packages`} onClick={collapseMobileMenu} className='mm-item'>
						<div className='arrow-right'></div> Packages & Pricing
					</Link> : null }

				<NavDropdown
					title={<div className='mm-item'>
						<div className={myAccountMenuShown ? 'arrow-right arrow-down' : 'arrow-right'}></div> My Account
					</div>}
					onClick={event => myAccountSubMenuClick(event)}
					show={myAccountMenuShown}
					onMouseEnter={() => setMyAccountMenuShown(true)}
					onMouseLeave={() => setMyAccountMenuShown(false)}>

					<div className="mm-dropdown-submenu">
						<HashLink to='/secure/my-account#PracticeAreas' onClick={collapseMobileMenu} className='mm-item'>
							<div className='arrow-right'></div> Manage Favorite Practice Areas
						</HashLink>
						<HashLink to='/secure/my-account#BarInfo' onClick={collapseMobileMenu} className='mm-item'>
							<div className='arrow-right'></div> Manage Bar Information
						</HashLink>
						<HashLink to='/secure/my-account#PurchaseHistory' onClick={collapseMobileMenu} className='mm-item'>
							<div className='arrow-right'></div> View Purchase History
						</HashLink>
					</div>

				</NavDropdown>

				<Link to='/secure/cle-referral-program' onClick={collapseMobileMenu} className='mm-item'>
					<div className='arrow-right'></div> Refer a Friend - Earn $20
				</Link>

				<a onClick={onLogoutClick} href='/' className='mm-item logout-button'>Log Out</a>

				<a className='mm-item' href='https://help.lexvid.com/' target='_blank' rel='noopener noreferrer'>Help</a>

				<a className='mm-item justify-content-center d-flex' href='tel:1-877-327-1226'>Call 1-877-327-1226</a>


			</Nav>

		</Navbar.Collapse>


	</Navbar>
}

export default AuthenticatedMenu;
