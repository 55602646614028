import React, { useEffect, Suspense, lazy } from 'react';
import { Switch, withRouter, Route, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCampaign, setReferredBy } from './actions/miscActions';
import { getImageMetaData } from './actions/contentActions';
import { trackMemberPageRequest } from './actions/memberActions';
import { isEmpty } from 'lodash';
import useSendPageViewGA from "./hooks/useSendPageViewGA";
import useInitGAGTM from "./hooks/useInitGAGTM";

// Components
const Home = lazy(() => import('./components/Home'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./components/TermsOfUse'));
const SignUp = lazy(() => import('./components/SignUp'));
const SignIn = lazy(() => import('./components/SignIn'));
const FinancialAid = lazy(() => import('./components/FinancialAid'));
const State = lazy(() => import('./components/State'));
const PackagesOld = lazy(() => import('./components/Packages/Old'));
const Packages = lazy(() => import('./components/Packages/Packages'));
const PackagesNoState = lazy(() => import('./components/PackagesNoState'));
const PackagesLanding = lazy(() => import('./components/Packages/PackagesLanding'));
const Courses = lazy(() => import('./components/Courses'));
const About = lazy(() => import('./components/About'));
const Contact = lazy(() => import('./components/Contact'));
const Speakers = lazy(() => import('./components/Speakers'));
const StateDiscounts = lazy(() => import('./components/StateDiscounts'));
const FreeCLE = lazy(() => import('./components/FreeCLE'));
const LawFirmPage = lazy(() => import('./components/LawFirmPage'));
const SpeakerApplication = lazy(() => import('./components/SpeakerApplication'));
const Speaker = lazy(() => import('./components/Speaker'));
const Product = lazy(() => import('./components/Product'));
const CompletedCourses = lazy(() => import('./components/CompletedCourses/CompletedCourses'));
const CompletedCoursesOld = lazy(() => import('./components/CompletedCourses/Old/CompletedCoursesOld'));
const Requirements = lazy(() => import('./components/Requirements'));
const ProtectedRoute = lazy(() => import('./components/common/ProtectedRoute'));
const CourseDetails = lazy(() => import('./components/CourseDetails'));
const SecureCourses = lazy(() => import('./components/SecureCourses/SecureCourses'));
const StyleGuide = lazy(() => import('./components/StyleGuide/StyleGuide'));
const AccountPage = lazy(() => import('./components/AccountPage'));
const CheckOut = lazy(() => import('./components/CheckOut'));
const ComplianceReport = lazy(() => import('./components/common/ComplianceReport'));
const Receipt = lazy(() => import('./components/common/Receipt'));
const CourseEvaluation = lazy(() => import('./components/CourseEvaluation'));
const SecurePackagesOld = lazy(() => import('./components/SecurePackages'));
const SecurePayment = lazy(() => import('./components/SecurePayment'));
const SecureProduct = lazy(() => import('./components/SecureProduct/SecureProduct'));
const SecureMultiStatePackagesOld = lazy(() => import('./components/SecureMultiStatePackages'));
const SecureMultiStateProduct = lazy(() => import('./components/SecureMultiStateProduct'));
const ReferralProgram = lazy(() => import('./components/ReferralProgram'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const MobileApp = lazy(() => import('./components/MobileApp'));
const ChangePassword = lazy(() => import('./components/ChangePassword/ChangePassword'));
const MultiState = lazy(() => import('./components/MultiState'));
const MultiStatePackagesOld = lazy(() => import('./components/MultiStatePackages'));
const CustomerReviews = lazy(() => import('./components/CustomerReviews'));
const MultiStateProduct = lazy(() => import('./components/MultiStateProduct'));
const CourseReviews = lazy(() => import('./components/CourseReviews'));
const CourseTranscript = lazy(() => import('./components/CourseTranscript'));
const NotFoundPage = lazy(() => import('./components/NotFoundPage/NotFoundPage'));
const ErrorFallback = lazy(() => import('./components/common/ErrorFallback'));
const CourseTimeout = lazy(() => import('./components/CourseTimeout'));
const LoadingPage = lazy(() => import('./components/LoadingPage/LoadingPage'));
const ForgotPasswordConfirmation = lazy(() => import('./components/ForgotPassword/ForgotPasswordConfirmation'));
const SecureHome = lazy(() => import('./components/SecureHome/SecureHome'));
const SecureBundle = lazy(() => import('./components/SecureBundle/SecureBundle'));
const SecurePlaylist = lazy(() => import('./components/SecurePlaylist/SecurePlaylist'));
const LiveCourses = lazy(() => import('./components/LiveCourses/LiveCourses'));
const SecureUserLiveCourses = lazy(() => import('./components/SecureUserLiveCourses/SecureUserLiveCourses'));
const PackagesOfferOndemand = lazy(() => import('./components/PackagesOfferOndemand/PackagesOfferOndemand'));
// const PackagesOfferLive = lazy(() => import('./components/PackagesOfferLive/PackagesOfferLive'));
const ForgotPasswordLoginLanding = lazy(() => import('./components/ForgotPassword/ForgotPasswordLoginLanding'));
const BlogIndex = lazy(() => import('./components/Blog/BlogIndex/BlogIndex'));
const BlogPost = lazy(() => import('./components/Blog/BlogPost/BlogPost'));

const Routes = (props) => {
	const { location } = props;

	// Initialize GA and GTM
	useInitGAGTM();
	
	// Send page views to GA
	useSendPageViewGA();

	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname, search, hash } = useLocation();
	const pageUrl = pathname + search + hash;

	const imageMetaData = useSelector(state => state.content.imageMetaData);
	// console.log(location)
	useEffect(() => {
		const queryString = location.search;

		// after cookies are written, we want to redirect to lexvid.com?utm_campaign=referral-program&utm_source=lexvid&utm_medium=referral
		const urlParams = new URLSearchParams(queryString);

		const campaign = urlParams.get('utm_campaign');
		const campaignSource = urlParams.get('utm_source');
		const referredBy = urlParams.get('referredby');

		if (!isEmpty(referredBy)) {
			dispatch(setReferredBy(referredBy));

			history.push(`${location.pathname}?utm_campaign=referral-program&utm_source=lexvid&utm_medium=referral`)
			return;
		}

		if (isEmpty(imageMetaData))
			dispatch(getImageMetaData());

		if (campaign !== null) {
			dispatch(setCampaign(campaign, campaignSource));

			//history.push(`${location.pathname}`);

			return;
		}
	}, [history, dispatch, imageMetaData, location]);

	const urlWithoutQueryString = pageUrl.split("?")[0].toLowerCase();
	useEffect(() => {
		dispatch(trackMemberPageRequest(urlWithoutQueryString));
	}, [dispatch, urlWithoutQueryString]);

	const FallBack = () => {
		return (
			<div className="page-loading-wrap">
				<div>Page is Loading...</div>				
			</div>
		)
	}

	return (
		<Suspense fallback={<FallBack />}>
			<Switch>
				<Route path='/' exact component={Home} />

				<Route path='/loading' exact component={LoadingPage} />

				<ProtectedRoute path='/secure/live' component={SecureUserLiveCourses} redirectToLogin={true} />
				<ProtectedRoute path='/secure/packages/live' component={LiveCourses} redirectToLogin={true} />

				<ProtectedRoute path='/secure/bundle/:bundleId' component={SecureBundle} redirectToLogin={true} />
				<ProtectedRoute path='/secure/playlist' component={SecurePlaylist} redirectToLogin={true} />

				{/* Secure Courses Listing & Details */}
				<ProtectedRoute path='/secure/completed/:state' component={CompletedCourses} redirectToLogin={true} />
				<ProtectedRoute path='/secure/completed-old/:state' component={CompletedCoursesOld} redirectToLogin={true} />

				<ProtectedRoute path='/secure/courses/:practiceArea' component={SecureCourses} redirectToLogin={true} />

				<ProtectedRoute path='/secure/courses' component={SecureCourses} redirectToLogin={true} />

				{/* Secure State Pages */}
				<ProtectedRoute path='/secure/requirements/:state' component={Requirements} redirectToLogin={true} />
				<ProtectedRoute path='/secure/requirements' component={Requirements} redirectToLogin={true} />

				<ProtectedRoute path='/secure/cle-course/:courseUrl/reviews' component={CourseReviews} />
				<ProtectedRoute path='/secure/cle-course/:courseUrl/timeout' component={CourseTimeout} redirectToLogin={true} />
				<ProtectedRoute path='/secure/cle-course/:courseUrl/evaluation' component={CourseEvaluation} redirectToLogin={true} />
				<ProtectedRoute path='/secure/cle-course/:courseUrl/preview' component={CourseDetails} />
				<ProtectedRoute path='/secure/cle-course/:courseUrl/transcript' component={CourseTranscript} />
				<ProtectedRoute path='/secure/cle-course/:courseUrl' component={CourseDetails} redirectToLogin={true} />


				{/* Secure Purchase Flow */}
				<ProtectedRoute path='/secure/packages/offer-live' component={LiveCourses} redirectToLogin={true} />
				<ProtectedRoute path='/secure/packages/offer-ondemand' component={PackagesOfferOndemand} redirectToLogin={true} />
				<ProtectedRoute path='/secure/packages/:package' component={SecureProduct} redirectToLogin={true} />
				<ProtectedRoute path='/secure/packages-old' component={SecurePackagesOld} redirectToLogin={true} />
				<ProtectedRoute path='/secure/packages' component={Packages} redirectToLogin={true} />

				<Route path='/secure/cle-packages/:package/:hoursOnDemand/:hoursLive' component={SecureProduct} redirectToLogin={true} />
				<Route path='/secure/cle-packages/:package/:hours' component={SecureProduct} redirectToLogin={true} />

				<ProtectedRoute path='/secure/cle-packages/:package' component={SecureProduct} redirectToLogin={true} />
				<ProtectedRoute path='/secure/cle-packages-old' component={SecurePackagesOld} redirectToLogin={true} />
				<ProtectedRoute path='/secure/cle-packages' component={Packages} redirectToLogin={true} />
				<ProtectedRoute path='/secure/multi-state-cle/packages/:product' component={SecureMultiStateProduct} redirectToLogin={true} />
				<ProtectedRoute path='/secure/multi-state-cle/packages-old' component={SecureMultiStatePackagesOld} redirectToLogin={true} />
				<ProtectedRoute path='/secure/multi-state-cle/packages' component={Packages} redirectToLogin={true} />

				<ProtectedRoute path='/secure/payment' component={SecurePayment} redirectToLogin={true} redirectToComponent={'/secure/cle-packages'} />




				{/* Secure Account Management */}
				<ProtectedRoute path='/secure/cle-referral-program' component={ReferralProgram} />
				{/* TODO: why redirectToLogin={true} here causes default redirect to /secure/my-account  */}
				<ProtectedRoute path='/secure/my-account' component={AccountPage} />
				<ProtectedRoute path='/secure/receipt' component={Receipt} redirectToLogin={true} />

				<Route path='/blog/category/:categoryUrl' component={BlogIndex} />
				<Route path='/blog/:postSlug' component={BlogPost} />
				<Route path='/blog' component={BlogIndex} />

				{/* Footer Routes */}
				<Route path='/about' component={About} />
				<Route path='/contact' component={Contact} />
				<Route path='/terms-of-use' component={TermsOfUse} />
				<Route path='/privacy-policy' component={PrivacyPolicy} />
				<Route path='/financial-aid-cle' component={FinancialAid} />
				<Route exact path='/law-firm-cle' component={LawFirmPage} />
				<Route path='/cle-referral-program' component={ReferralProgram} />
				<Route path='/free-continuing-legal-education' component={FreeCLE} />


				{/* State Pages */}
				<Route path='/multi-state-cle/packages/offer-live' component={LiveCourses} />
				<Route path='/multi-state-cle/packages/offer-ondemand' component={PackagesOfferOndemand} />
				<Route path='/multi-state-cle/packages/:product' component={MultiStateProduct} />
				<Route path='/multi-state-cle/packages-old' component={MultiStatePackagesOld} />
				<Route path='/multi-state-cle/packages' component={Packages} />
				<Route path='/multi-state-cle/courses/:practiceArea' component={Courses} />
				<Route path='/multi-state-cle/courses' component={Courses} />
				<Route path='/multi-state-cle' component={MultiState} />
				<Route path='/packages' component={PackagesLanding} />
				<Route path='/cle-packages' component={PackagesNoState} />

				{/* Account Management */}
				<Route path='/sign-up' component={SignUp} />
				<Route path='/sign-in' component={SignIn} />
				<Route path='/ChangePassword' component={ChangePassword} />
				<Route path='/forgot-password' component={ForgotPassword} />
				<Route path='/forgot-password-confirmation' component={ForgotPasswordConfirmation} />
				<Route path='/fpLogin' component={ForgotPasswordLoginLanding} />
				
				
				
				<Route path='/check-out' component={CheckOut} />


				<Route path='/cle-courses/:practiceArea' component={Courses} />
				<Route path='/cle-courses' component={Courses} />


				<Route path='/cle-course/:courseUrl/timeout' component={CourseTimeout} />
				<Route path='/cle-course/:courseUrl/reviews' component={CourseReviews} />
				<Route path='/cle-course/:courseUrl/transcript' component={CourseTranscript} />
				<Route path='/cle-course/:courseUrl' component={CourseDetails} />


				<Route path='/cle-speaker/application' component={SpeakerApplication} />
				<Route path='/cle-speakers/:speaker' component={Speaker} />
				<Route exact path='/cle-speakers' component={Speakers} />

				<Route path='/mobile-cle-app' component={MobileApp} />

				<Route path='/customer-reviews' component={CustomerReviews} />
				<Route path='/styleguide' component={StyleGuide} />

				<Route path='/error' component={ErrorFallback} />


				<ProtectedRoute path='/compliancereport' component={ComplianceReport} />

				<ProtectedRoute path='/secure' component={SecureHome} redirectToLogin={true} />

				<Route path='/:state?/courses/:practiceArea' component={Courses} />
				<Route path='/:state?/courses' component={Courses} />
				<Route path='/:state/requirements' component={Requirements} />
				<Route path='/:state/free' component={FreeCLE} />
				<Route path='/:state/discounts' component={StateDiscounts} />

				{/* <Route path='/:state/packages/offer-live' component={PackagesOfferLive} /> */}
				<Route path='/:state/packages/offer-live' component={LiveCourses} />
				<Route path='/:state/packages/offer-ondemand' component={PackagesOfferOndemand} />

				<Route path='/multistate/packages/live' component={LiveCourses} />
				<Route path='/:state/packages/live' component={LiveCourses} />
				
				<Route path='/:state/packages/:product/:hoursOnDemand/:hoursLive' component={Product} />
				<Route path='/:state/packages/:product/:hours' component={Product} />
				<Route path='/:state/packages/:product' component={Product} />
				<Route path='/:stateUrl/packages' component={Packages} />
				<Route path='/:state/packages-old' component={PackagesOld} />

				<Route exact path={'/:state-(cle|mcle)'} component={State} />

				<Route path="*" component={NotFoundPage} />

			</Switch>
		</Suspense>
	);
}

export default withRouter(Routes);