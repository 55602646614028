import {
	GET_TESTIMONIALS,
	GET_INCOMES,
	GET_CAMPAIGN,
	GET_REFERRER,
	GET_BARS,
	IS_EMAIL_VALID,
	IS_EMAIL_VALID_SUCCESS,
	GET_REFERRAL_PAGE,
	SUBMIT_LAW_FIRM_INQUIRY,
	SUBMIT_LAW_FIRM_INQUIRY_SUCCESS,
	SET_ADD_COURSE_AFTER_SIGNIN,
	SUBMIT_SPEAKER_INQUIRY,
	SUBMIT_SPEAKER_INQUIRY_SUCCESS,
	SET_REDIRECT_AFTER_SIGNIN,
	CLEAR_REDIRECT_AFTER_SIGNIN,
	SET_APP_ERROR,
	GET_BLOG_CATEGORIES,
	GET_BLOG_POSTS,
} from './types';
import MiscAPI from '../api/MiscAPI';
import Cookies from 'js-cookie';
import ContentAPI from '../api/ContentAPI';
import { isEmpty } from 'lodash';


export const getTestimonials = () => dispatch => {
	MiscAPI
		.getTestimonials()
		.then((testimonials) => {
			dispatch({ type: GET_TESTIMONIALS, payload: testimonials });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const getIncomes = () => dispatch => {
	MiscAPI
		.getIncomes()
		.then((incomes) => {
			dispatch({ type: GET_INCOMES, payload: incomes });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const setCampaign = (campaign, campaignSource) => dispatch => {
	const campaignValues = campaign.split('_');

	const campaignCookieValue = campaignValues[0];
	const campaignExpiration = parseInt(campaignValues[1]);

	// Set cookie with campaign value and expiration
	Cookies.set('campaign', campaignCookieValue, { expires: campaignExpiration });

	// Set cookie with campaign source and expiration
	Cookies.set('source', campaignSource, { expires: campaignExpiration });

	dispatch({ type: GET_CAMPAIGN, payload: campaignCookieValue });
}

export const getCampaign = () => dispatch => {
	const campaign = Cookies.get('campaign');

	dispatch({ type: GET_CAMPAIGN, payload: campaign });
}


export const setReferredBy = (referredBy) => dispatch => {

	const referCookieValue = parseInt(Buffer.from(referredBy, 'base64').toString('ascii'), 10);
	const referExpiration = parseInt(30);

	if (!isEmpty(referredBy)) {
		// Set cookie with campaign value and expiration
		Cookies.set('ReferredBy', referCookieValue, { expires: referExpiration });
	} else {
		Cookies.remove('ReferredBy');
	}
	// Set cookie with campaign source and expiration

	dispatch({ type: GET_REFERRER, payload: referCookieValue });
}


export const getReferredBy = () => dispatch => {
	const referCookieValue = Cookies.get('ReferredBy');

	dispatch({ type: GET_REFERRER, payload: referCookieValue });
}


export const getBars = () => dispatch => {
	MiscAPI
		.getBars()
		.then(bars => {
			// console.log(bars);
			dispatch({
				type: GET_BARS,
				payload: bars.data
			});
		})
		.catch(error => {
			console.log(error);
		});

}

export const validateEmail = (email) => dispatch => {
	dispatch({ type: IS_EMAIL_VALID });

	return new Promise((resolve, reject) => {
		MiscAPI
			.validateEmail(email)
			.then(response => {
				dispatch({ type: IS_EMAIL_VALID_SUCCESS, payload: response });
				resolve(response);
			})
			.catch(error => {
				console.log(error);
				reject(error);
			});
	});
}

export const getReferralContent = () => dispatch => {

	ContentAPI
		.getPageContent('referral-page')
		.then(data => {
			dispatch({ type: GET_REFERRAL_PAGE, payload: data });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const submitLawFirmInquiry = (data) => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: SUBMIT_LAW_FIRM_INQUIRY });

		MiscAPI
			.submitLawFirmInquiry(data)
			.then(response => {
				dispatch({ type: SUBMIT_LAW_FIRM_INQUIRY_SUCCESS, payload: response });
				resolve();
			})
			.catch(error => {
				console.log(error);
				reject(error);
			});
	});
}

export const submitSpeakerInquiry = (data, type) => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: SUBMIT_SPEAKER_INQUIRY });

		if (type === 'apply') {
			data = { ...data, StatusID: 1 };
		} else if (type === 'interest') {
			data = { ...data, StatusID: 2 };
		}


		MiscAPI
			.submitSpeakerInquiry(data)
			.then(response => {
				dispatch({ type: SUBMIT_SPEAKER_INQUIRY_SUCCESS, payload: response });
				resolve();
			})
			.catch(error => {
				console.log(error);
				reject(error);
			});
	});
}

export const setAddCourseAfterSignin = (courseID) => dispatch => {
	dispatch({ type: SET_ADD_COURSE_AFTER_SIGNIN, payload: courseID });
}

export const setRedirectAfterSignin = (pageUrl) => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch({ type: SET_REDIRECT_AFTER_SIGNIN, payload: pageUrl });
		resolve();
	})
}

export const clearRedirectAfterSignin = () => dispatch => {
	dispatch({ type: CLEAR_REDIRECT_AFTER_SIGNIN });
}

export const setAppError = (error) => dispatch => {
	dispatch({ type: SET_APP_ERROR, payload: error });
}

export const logAppError = (error) => dispatch => {

	// Content
	// {
	// 	"ErrorMessage": "English Description of Error",
	// 		"Exception": "Optional - Serialized exception or technical logs",
	// 			"StackTrace": "Optioan - Stack trace of exception",
	// 				"PageName": "Descriptive name of page - Payment",
	// 					"FullURL": "URL, including Query String Generating Error",
	// 						"InternalContext": "Internal State - useful for debugging.  Would love the entire state object"
	// }

	// const errorData = { ...error };

	// dispatch({ type: SET_APP_ERROR, payload: errorData });

	return new Promise((resolve, reject) => {
		resolve(true);

		// MiscAPI
		// 	.logAppError(errorData)
		// 	.then(response => {
		// 		resolve(response);
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 		reject(error);
		// 	});
	});
}

export const triggerAPIError = (errorCode) => dispatch => {

	if (errorCode > 0) {
		return new Promise((resolve, reject) => {
			MiscAPI
				.triggerAPIError(errorCode)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					// console.log(error);
					reject(error);
				});
		});
	}
}

export const getBlogCategories = () => dispatch => {
	return new Promise((resolve, reject) => {
		MiscAPI
			.getBlogCategories()
			.then((categories) => {
				dispatch({ type: GET_BLOG_CATEGORIES, payload: categories });
				resolve(categories);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	});
}

export const getBlogPosts = () => dispatch => {
	return new Promise((resolve, reject) => {
		MiscAPI
			.getBlogPosts()
			.then((posts) => {
				dispatch({ type: GET_BLOG_POSTS, payload: posts });
				resolve(posts);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	});
}

export const getPackagesContentWithKlavID = (klaviyoUniqueID, isMultiState) => dispatch => {
	return new Promise((resolve, reject) => {
		MiscAPI
			.getPackagesContentWithKlavID(klaviyoUniqueID, isMultiState)
			.then(data => {
				resolve(data);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	});
}