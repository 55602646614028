import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import { logAppError } from './actions/miscActions';
// import { setAppError } from './actions/miscActions';
import rootReducer from './reducers';

const middleware = [thunk];

const saveToSessionStorage = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		sessionStorage.setItem('state', serializedState);
	} catch (e) {
		console.log(e);
	}
}

const loadFromSessionStorage = () => {
	try {
		const serializedState = sessionStorage.getItem('state');
		if (serializedState === null)
			return undefined;
		return JSON.parse(serializedState);
	} catch (e) {
		console.log(e);
		return undefined;
	}
}

const registerHandlers = store => {

	window.addEventListener("error", (event) => {
		// console.log("--- error from listener: ");
		// console.log(event.error);

		// const eventError = event.error;

		// if (store) {
		// 	let errorData = {
		// 		ErrorMessage: event.message,
		// 		// Exception: eventError,
		// 		StackTrace: eventError.stack,
		// 		PageName: window.location.pathname, // TODO: need to get component name
		// 		FullURL: window.location.href,
		// 		InternalContext: JSON.stringify(store.getState())
		// 	};

		// 	store.dispatch(logAppError(errorData));
		// }
	});

	window.addEventListener("unhandledrejection", (event) => {
		// console.log("--- unhandledrejection from listener: ");
		// console.log(event);
		// store.dispatch(setAppError({ message: event?.reason?.message }));
	});
}

const persistedState = loadFromSessionStorage();

const store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));

// register error and unhandledrejection listeners
registerHandlers(store);

store.subscribe(() => saveToSessionStorage(store.getState()));

export default store;