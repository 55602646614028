import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { completeLiveCourse } from "../../../actions/accountActions";
import { CHECK_MEMBER_LIVE_COURSE, COMPLETE_LIVE_COURSE_FAILED } from "../../../actions/types";
import { useState } from "react";
import { useHistory } from "react-router-dom";


export default function EnterLiveEventCodeModal(props) {
	
	const { show, handleClose, liveEvent } = props;
	
	const dispatch = useDispatch();
	const history = useHistory();

	// const liveCourseCodeError = useSelector(state => state.account.liveCourseCodeError);
	
	const [courseCode, setCourseCode] = useState('');
	const [courseCodeError, setCourseCodeError] = useState(null);

	const submitCode = () => {

		if (!courseCode || courseCode.trim().length === 0) {
			setCourseCodeError('Please enter code.');
			return;
		}
		else setCourseCodeError(null);

		dispatch(completeLiveCourse(liveEvent.LiveEventsAttendeeID, courseCode.trim()))
			.then(data => {
				if (data === "Success") {
					// close modal
					handleClose();
					// clear member liveCourse in redux store
					dispatch({ type: CHECK_MEMBER_LIVE_COURSE, payload: null })
					// redirect to Course Eval
					history.push(`/secure/cle-course/${liveEvent.CourseURL}/evaluation`);
				}
			})
			.catch(error => {
				if (error === "Incorrect Code") setCourseCodeError('Incorrect Course Code: Please enter the code for this course.');
				else setCourseCodeError('Error has occured. Please try again later.');
				console.log('completeLiveCourse error: ', error); 
			});
	}

	const onCourseCodeChange = e => {
		// reset error in redux store
		dispatch({ type: COMPLETE_LIVE_COURSE_FAILED, payload: null })
		const newVal = e.target.value
		if (!newVal || newVal.length === 0) setCourseCodeError(null)
		setCourseCode(newVal)
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			submitCode();
		}
	}


	return <Modal className="enter-code-modal" size="lg" centered={true} backdrop="static"
		show={show} onHide={handleClose} >
		<Modal.Header>
			<h4>Enter Your Course Code</h4>
		</Modal.Header>

		<Modal.Body>
			<label>Course Code</label><span className={courseCodeError === 'Please enter code.' ? "error": "d-none"}>&nbsp;*</span>
			<input name="code" className="form-control" value={courseCode} 
				onKeyDown={handleKeyDown} onChange={onCourseCodeChange}></input>
			{courseCodeError ? <span className="error">{courseCodeError}</span> : null}				
		</Modal.Body>

		<Modal.Footer>
			<Button className="btn btn-primary" onClick={() => submitCode()}>Submit</Button>
			<Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
		</Modal.Footer>
	</Modal>
}