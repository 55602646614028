import { useEffect } from "react";
// import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

export default function useInitGAGTM() {
	useEffect(() => {

		// Init GA and GTM
		// const setGA = () => {
		// 	// console.log("Initializing GA");
		// 	ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
		// };
	
		const setGTM = () => {
			
			const tagManagerArgs = {
				gtmId: process.env.REACT_APP_GTM_ID,
				events: {
					sendUserInfo: 'userInfo'
				}
			}
		
			TagManager.initialize(tagManagerArgs)
		};
	
		// setGA();
		setGTM();
	}, []);	

}