import {
	GET_PRODUCT_PRICE, PURCHASE_PRODUCT,
	CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILED, RESET_PURCHASE_PRODUCT, COMPLETE_LIVE_COURSE, COMPLETE_LIVE_COURSE_FAILED, UPDATE_SELECTED_LIVE_EVENTS, REMOVE_FROM_SELECTED_LIVE_EVENTS, SET_CART_ID, UPDATE_IS_LIVE_COUPON_BAD, 
	SET_LIVE_CART,
	REMOVE_FROM_LIVE_CART,
	GET_PRODUCTS_PRICE,
} from './types';
import AccountAPI from '../api/AccountAPI';
import { isEmpty } from 'lodash';

export const getProductPrice = (productInfo) => dispatch => {
	return new Promise((resolve, reject) => {

		if (productInfo.isLive === 1) {
			AccountAPI
				.getProductLivePrice(productInfo)
				.then((productPrice) => {
					dispatch({ type: GET_PRODUCT_PRICE, payload: productPrice });
					resolve(productPrice);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				})
		} else {
			AccountAPI
				.getProductPrice(productInfo)
				.then((productPrice) => {
					dispatch({ type: GET_PRODUCT_PRICE, payload: productPrice });
					resolve(productPrice);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				})
		}
	})
}

export const getProductPrice2 = (productInfo) => dispatch => {
	return new Promise((resolve, reject) => {

		AccountAPI
			.getProductPrice2(productInfo)
			.then((productPrice) => {
				dispatch({ type: GET_PRODUCTS_PRICE, payload: productPrice });
				resolve(productPrice);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}

export const purchaseProduct = (productInfo, cartId) => dispatch => {

	return new Promise((resolve, reject) => {
		AccountAPI
			.purchaseProduct(productInfo, cartId)
			.then((purchaseProduct) => {
				dispatch({ type: PURCHASE_PRODUCT, payload: purchaseProduct });
				if (purchaseProduct) {
					// dispatch(updateUser(purchaseProduct?.Member));

					if (purchaseProduct.IsAccountCharged === true)
						dispatch(resetPurchaseProduct());
				}
				resolve(purchaseProduct);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}

export const resetPurchaseProduct = () => dispatch => {
	dispatch({ type: RESET_PURCHASE_PRODUCT });
}

export const changePassword = (memberIdentifier, password, confirmPassword) => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: CHANGE_PASSWORD });

		AccountAPI
			.changePassword(memberIdentifier, password, confirmPassword)
			.then(response => {
				// A Bad Email address will return:
				// { "MemberIdentifier": "00000000-0000-0000-0000-000000000000", "IsSuccessful": false, "ErrorMessage": "Unable to locate member", "StackTrace": "" }
				if (response.IsSuccessful) {
					dispatch({
						type: CHANGE_PASSWORD_SUCCESS,
						payload: response.data
					});
					resolve(response.data);
				} else {
					dispatch({
						type: CHANGE_PASSWORD_FAILED,
						payload: response.ErrorMessage
					});
					reject();
				}
			})
			.catch(error => {
				dispatch({
					type: CHANGE_PASSWORD_FAILED,
					payload: error
				});
				reject(error);
			});
	});
}

export const updatePurchaseInfo = (productInfo) => dispatch => {
	dispatch({ type: PURCHASE_PRODUCT, payload: productInfo });
}

export const completeLiveCourse = (liveEventAttendeeID, completionCode) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.completeLiveCourse(liveEventAttendeeID, completionCode)
			.then(response => {
				if (response === "Success") {
					dispatch({ type: COMPLETE_LIVE_COURSE, payload: liveEventAttendeeID});
					resolve(response);
				} else {
					dispatch({ type: COMPLETE_LIVE_COURSE_FAILED, payload: response });
					reject(response);
				}
			})
			.catch(error => {
				dispatch({ type: COMPLETE_LIVE_COURSE_FAILED, payload: error });
				reject(error);
			});
	});
}

export const rescheduleLiveCourse = (LiveEventAttendeeID, newLiveEventId) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.rescheduleLiveCourse(LiveEventAttendeeID, newLiveEventId)
			.then(response => {
				if (response === "Success") {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const addProductToLiveCart = (product, cartId, memberId) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.addProductToLiveCart(product, cartId, memberId)
			.then(response => {
				dispatch({ type: SET_CART_ID, payload: response.cartId });
				dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: response.products });
				dispatch({ type: SET_LIVE_CART, payload: response.products });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const removeProductFromLiveCart = (cartId, liveEventsId) => dispatch => {
	if (!cartId || isEmpty(liveEventsId)) return Promise.resolve();

	return new Promise((resolve, reject) => {
		AccountAPI
			.removeProductFromLiveCart(cartId, liveEventsId)
			.then(response => {
				dispatch({ type: REMOVE_FROM_SELECTED_LIVE_EVENTS, payload: liveEventsId });
				dispatch({ type: REMOVE_FROM_LIVE_CART, payload: liveEventsId });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const getLiveCart = (cartId, isFractionalPrice = false) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.getLiveCart(cartId, isFractionalPrice)
			.then(response => {
				dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: response });
				dispatch({ type: SET_LIVE_CART, payload: response });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const clearLiveCart = (cartId) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.clearLiveCart(cartId)
			.then(response => {
				dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: [] });
				dispatch({ type: SET_LIVE_CART, payload: [] });
				dispatch({ type: SET_CART_ID, payload: null });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const getLiveCartPrice = (cartId, couponCode, alreadyAppliedDiscount, isFractionalPrice) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.getLiveCartPrice(cartId, couponCode, alreadyAppliedDiscount, isFractionalPrice)
			.then(response => {
				let isLiveCouponBad = false;

				if (!isEmpty(response)) {
					isLiveCouponBad = !response[0]?.IsSuccessful;
				}

				dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: response });
				dispatch({ type: SET_LIVE_CART, payload: response });
				dispatch({ type: UPDATE_IS_LIVE_COUPON_BAD, payload: isLiveCouponBad });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const sendStartedCheckout = (accountModel) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.sendStartedCheckout(accountModel)
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}