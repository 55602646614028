import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './Footer.scss';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import CookieConsent from 'react-cookie-consent';
import googleLogo from '../../assets/icons/google-play-wb-logo.png';
import appleLogo from '../../assets/icons/apple-wb-logo.png';
// const rackspaceURL = process.env.REACT_APP_RACKSPACE_URL;
import KayakoChat from '../common/Chat/KayakoChat';


const Footer = (props) => {
	const location = useLocation();
    const authenticated = useIsAuthenticated();

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    return (
        <div className='footer-container'>
            <Container>
                <Navbar variant='' sticky='bottom'>

                    <Nav className='text-links-wrap'>
                        <ul>
                            <li>
                                <Link to='/about' role="menuitem" className='footer-link'>About</Link>
                            </li>
                            <li>
                                <a href='https://help.lexvid.com/' role="menuitem" target='_blank' rel='noopener noreferrer' className='footer-link'>FAQs</a>
                            </li>
                            <li>
                                <Link to='/contact' role="menuitem" className='footer-link'>Contact</Link>
                            </li>
                            <li className="mr-2">
                                <a className='footer-link' role="menuitem" href='tel:1-877-327-1226'>Call 1-877-327-1226</a>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to='/blog' role="menuitem" className='footer-link'>Blog</Link>
                            </li>
                            <li>
                                <Link to='/terms-of-use' role="menuitem" className='footer-link'>Terms of Use</Link>
                            </li>
                            <li>
                                <Link to='/privacy-policy' role="menuitem" className='footer-link'>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to='/financial-aid-cle' role="menuitem" className='footer-link'>Financial Aid</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to='/law-firm-cle' role="menuitem" className='footer-link'>Law Firm &amp; Group CLE</Link>
                            </li>
                            <li>
                                <Link to={`${authenticated ? '/secure' : ''}/cle-referral-program`}  role="menuitem" className='footer-link'>Referral Program</Link>
                            </li>
                            <li>
                                <Link to='/free-continuing-legal-education' role="menuitem" className='footer-link'>Free CLE</Link>
                            </li>
                            <li className='d-lg-none'>
                                <Link to='/cle-speakers' role="menuitem" className='footer-link'>LexVid CLE Speakers</Link>
                            </li>
                            <li className='d-lg-none'>
                                <Link to='/cle-speaker/application' role="menuitem" className='footer-link'>Become A Faculty Member</Link>
                            </li>
                            <li className='d-lg-none'>
                                <Link to='/mobile-cle-app' role="menuitem" className='footer-link'>Mobile App</Link>
                            </li>
                        </ul>
                        <ul className='d-none d-lg-block'>
                            <li>
                                <Link to='/cle-speakers' role="menuitem" className='footer-link'>LexVid CLE Speakers</Link>
                            </li>
                            <li>
                                <Link to='/cle-speaker/application' role="menuitem" className='footer-link'>Become A Faculty Member</Link>
                            </li>
                            <li>
                                <Link to='/mobile-cle-app' role="menuitem" className='footer-link'>Mobile App</Link>
                            </li>
                        </ul>
                    </Nav>

                    <Nav className='social-media-apps-icons'>
                        <div>
                            <a href='https://twitter.com/lexvid' aria-label='Twitter' target='_blank' rel='noopener noreferrer'>
                                <i className="fab fa-twitter fa-3x"></i>
                            </a>
                            <a href='https://www.facebook.com/lexvidcle' aria-label='Facebook' target='_blank' rel='noopener noreferrer'>
                                <i className="fab fa-facebook-f fa-3x"></i>
                            </a>
                            <a href='https://www.linkedin.com/company/lexvid' aria-label='LinkedIn' target='_blank' rel='noopener noreferrer'>
                                <i className="fab fa-linkedin fa-3x"></i>
                            </a>
                            <a href='https://www.youtube.com/channel/UCKmbK6KRKVb1C2rQqcv7kAw' aria-label='Youtube' target='_blank' rel='noopener noreferrer'>
                                <i className="fab fa-youtube fa-3x"></i>
                            </a>
                        </div>
                        <div>
                            <a href='https://apps.apple.com/us/app/lexvid/id1439636615' aria-label='Apple App Store' target='_blank' rel='noopener noreferrer'>
                                <img src={appleLogo} className='apple-app-logo' alt='apple app' width='160' height='47' />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.lexvidnative&hl=en_US&gl=US' aria-label='Google Play Store' target='_blank' rel='noopener noreferrer'>
                                <img src={googleLogo} className='google-play-logo' alt='google app' width='168' height='47' />
                            </a>
                        </div>
                    </Nav>
                </Navbar>
                <div className='footer-brand'>
                    @ {getCurrentYear()} LexVid Services, Inc.<br />
                    {/*
                    <label className='cb-admin-wrap'>Admin?
                        <Form.Check checked={isAdmin} onChange={() => changeAdmin()} ref={isAdminRef}></Form.Check>
                    </label>
                    */}
                </div>
            </Container>

            {/* {!authenticated && showCookieAcceptance &&
                <CookieAcceptance close={closeCookieAcceptanceClick} />
            } */}

            {!authenticated &&
                <div className='cookie-acceptance-wrap'>
                    <CookieConsent disableStyles={true} buttonText='' buttonClasses='fal fa-times-square fa-2x'>
                        This site uses cookies to help create a great user experience for you, and to help guide us in our continual improvement. By using LexVid, you consent to our <Link to='/privacy-policy'>Privacy Policy</Link> and use of cookies.
                    </CookieConsent>
                </div>
            }

			{/* Show kayako chat on every page except for payment/checkout/product */}
			{(!location.pathname.includes('payment') && !location.pathname.includes('check-out') && !location.pathname.includes('packages')) ?
				<KayakoChat autoOpen={location.pathname.includes('contact')} /> : null
			}
        </div>
    );
}

export default Footer;