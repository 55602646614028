import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './Header.scss';
import Pushdown from '../common/Pushdown/Pushdown';
import { checkLiveCourse, getMemberCompliance } from '../../actions/memberActions';
import Compliance from '../common/Compliance';
import { Helmet } from 'react-helmet';
import Utils from '../../utils';
import { getPageContent } from '../../actions/contentActions';
import NotAuthenticatedMenu from './NotAuthenticatedMenu';
import AuthenticatedMenu from './AuthenticatedMenu';
import { isEmpty } from 'lodash';
import useIsSecurePage from '../../hooks/useIsSecurePage';
import NoIndexMeta from '../NoIndexMeta/NoIndexMeta';
import useInterval from '../../hooks/useInterval';
import LiveCourseBanner from "./LiveCourseBanner";
import OrganizationDataMarkup from "./OrganizationDataMarkup";

const pageNamesToNotGetContent = [
	'cle-course-test',
	'checkout',
	'state',
	'packages',
	//'cle-packages',
	'courses',
	'cle-courses',
	'course-reviews',
	'customer-reviews',
	'speakerapplication',
	'free',
	'free-continuing-legal-education',
	'terms-of-use',
	'product-custom',
	'secure-product-custom',
	'product-a-la-carte',
	'multi-state-cle',
	'requirements',
	'check-out',
	'fplogin', 
	'speakers',
	'blogDetails',
	'course-reviews'
];

const Header = () => {

	const dispatch = useDispatch();
	const location = useLocation();
	const isSecurePage = useIsSecurePage();
	const isBlogPage = location?.pathname.includes("/blog");

	const statesState = useSelector(state => state.states);
	const authState = useSelector(state => state.auth);
	const contentState = useSelector(state => state.content);
	const pageContent = useSelector(state => state.content.content);
	const memberState = useSelector(state => state.member);
	const authenticated = authState ? (authState.token ? true : false) : false;

	const userIdentifier = authState?.user?.Identifier;
	const memberID = authState?.user?.ID;
	const pushdown = contentState?.content?.ContentBlock?.PUSHDOWN;
	const compliance = memberState?.compliance;
	const liveCourse = memberState?.liveCourse;

	useEffect(() => {
		if (!isEmpty(userIdentifier)) {
			dispatch(getMemberCompliance(userIdentifier));
		}
	}, [userIdentifier, dispatch])


	const useMountEffect = (fun) => useEffect(fun, [location, statesState.stateURL]);

	useMountEffect(() => {

		const pathname = location.pathname.toLowerCase();
		const pageName = Utils.getPageName(pathname, statesState);
		// console.log(pageName);
		
		if (pathname.includes('cle-course') && !pathname.includes('cle-courses') && !pathname.includes('/reviews')) {
			dispatch(getPageContent(pageName, statesState.stateURL));
			return;
		}


		if (pageName === "financial-aid-cle") {
			dispatch(getPageContent(pageName, statesState.stateURL));
		}

		if (!isEmpty(pageName) && !pageNamesToNotGetContent.includes(pageName) && !pageName.includes('-cle') && !pageName.includes('-mcle')) {
			if (['multistate-product-custom', 'product-custom-live-multistate', 
						'multistate-product-unlimited', 'product-unlimited-live-multistate', 
						'multistate-product-a-la-carte', 'multistate-product-all-inclusive'].includes(pageName.toLowerCase())) {
				dispatch(getPageContent(pageName, statesState.multiStateArray?.map(state => state.ID)));
			} else {
				dispatch(getPageContent(pageName, statesState.stateURL));
			}

		} else {
			if (pageName === 'mobile-cle-app')
				dispatch(getPageContent(pageName, statesState.stateURL));
		}

		if (pageName === 'packages') document.body.classList.add('packages-page');
		else document.body.classList.remove('packages-page');
	})

	// Check live course on 1 min interval
	useInterval(() => {
		// console.log(memberID);
		if(authenticated && memberID) {
			dispatch(checkLiveCourse(memberID));
		}
	}, 1000 * 60);


	return (
		<div>
	
			<OrganizationDataMarkup />

			{isSecurePage &&
				<NoIndexMeta />
			}

			<Helmet>
				<title>{pageContent?.MetaTag?.Title ?? 'LexVid'}</title>
				<meta name="description" content={pageContent?.MetaTag?.Description ?? ''} />
				<script>
					{`
            (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){acsbJS.init({ statementLink: '', footerHtml: '', hideMobile: false, hideTrigger: false, disableBgProcess: false, language: 'en', position: 'left', leadColor: '#00A7E1', triggerColor: '#00A7E1', triggerRadius: '50%', triggerPositionX: 'left', triggerPositionY: 'bottom', triggerIcon: 'people', triggerSize: 'bottom', triggerOffsetX: 20, triggerOffsetY: 20, mobile: { triggerSize: 'small', triggerPositionX: 'left', triggerPositionY: 'bottom', triggerOffsetX: 20, triggerOffsetY: 20, triggerRadius: '20' } }); }; h.appendChild(s); })();
          `}
				</script>
			</Helmet>
			
			{!authenticated &&
				<NotAuthenticatedMenu />
			}

			{authenticated &&
				<AuthenticatedMenu />
			}

			{}
			{authenticated && compliance && compliance.length > 0 && compliance.some(c => !c.HideComplianceBar) && !isBlogPage &&
				<Compliance data={compliance} />
			}

			{/* show if LiveCourseCheck data is present */}
			{authenticated && liveCourse && !isBlogPage ? <LiveCourseBanner liveCourse={liveCourse} /> : null}

			{/* If the block is present and at least 10 characters, show the pushdown with the content. */}
			{pushdown?.Content && pushdown?.Content.length > 9 && !isBlogPage &&
				<Pushdown message={pushdown?.Content} />
			}			
		</div>
	);	
}

export default Header;