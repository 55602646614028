import {
	LOGIN, LOGIN_SUCCESS, LOGIN_FAILED,
	LOGOUT, LOGOUT_SUCCESS,
	SET_USER, UNSET_USER, SET_OFFLINE_USER,
	SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILED, SIGNUP_RESET, CLEAR_MEMBER, SET_ADMIN, UNSET_ADMIN,
	FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILED,
	UPDATE_USER, CHECKOUT, CHECKOUT_SUCCESS, CHECKOUT_FAILED, SET_COOKIES_ACCEPTED,
	// REFRESH_USER
} from '../actions/types';
import AuthAPI from '../api/AuthAPI';
import Cookies from "js-cookie";
import axios from '../utils/axiosInterceptors';
import store from '../store';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';

export const refreshUser = () => dispatch => {
	const token = Cookies.get('lexvid_token');

	return new Promise((resolve, reject) => {
		if (token) {
			AuthAPI
				.refreshUser()
				.then(member => {
					if (member) {
						// dispatch({ type: REFRESH_USER, payload: member });
						dispatch(setUser(member, member.Identifier));
						resolve(member);
					}
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				})
		} else {
			// reject(false);
		}
	})
}


export const signup = (email, password, firstName, lastName, isParalegal, bars) => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: SIGNUP });
		const referrerCookie = Cookies.get('ReferredBy');

		bars.forEach(bar => {
			// need to pass null instead of N/A(-1)
			if (bar.DayID === -1) bar.DayID = null;
			if (bar.MonthID === -1) bar.MonthID = null;
			if (bar.YearID === -1) bar.YearID = null;
		})

		AuthAPI
			.signup(email, password, firstName, lastName, isParalegal, bars, referrerCookie)
			.then(response => {

				if (response.data.IsSuccessful) {
					Cookies.set('lexvid_token', response.data.Identifier, { expires: 30 });

					const user = { ...response.data.Member, IsParalegal: response.data.IsParalegal };

					dispatch(setUser(user, response.data.Identifier));

					dispatch({
						type: SIGNUP_SUCCESS,
						payload: response.data
					});
					resolve();

				} else {
					dispatch({
						type: SIGNUP_FAILED,
						payload: response.data.ErrorMessage || 'Signup attempt not successful'
					});
					Cookies.remove('lexvid_token');
					reject();
				}
			})
			.catch(error => {
				dispatch({
					type: SIGNUP_FAILED,
					payload: error
				});
				reject(error);
			});
	});
}

export const signupReset = () => dispatch => {
	dispatch({ type: SIGNUP_RESET });
}

export const login = (email, password) => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: LOGIN });

		AuthAPI
			.login(email, password)
			.then(response => {
				if (response.data.IsSuccessful) {
					Cookies.set('lexvid_token', response.data.Identifier, { expires: 30 });

					//  Expand the Member object to match api/Member/shallow result
					let { Member, EmailAddress, ...user } = {
						...response.data,
						Bars: response.data.Member?.Bars,
						Bundles: response.data.Member?.Bundles,
						Email: response.data.Member?.Email,
						FirstName: response.data.Member?.FirstName,
						ID: response.data.Member?.ID,
						LastName: response.data.Member?.LastName,
						MyPracticeAreas: response.data.Member?.MyPracticeAreas,
						PopularPracticeAreas: response.data.Member?.PopularPracticeAreas,
						AllPracticeAreas: response.data.Member?.AllPracticeAreas,
						PaidCredits: response.data.Member?.PaidCredits,
						FreeCredits: response.data.Member?.FreeCredits,
						PopupInterval: response.data.Member?.PopupInterval,
						IsPopupTimeout: response.data.Member?.IsPopupTimeout,
					};
					// console.log(user);
					dispatch(setUser(user, response.data.Identifier));

					dispatch({
						type: LOGIN_SUCCESS,
						payload: response.data
					});
					resolve();
				} else {
					dispatch({
						type: LOGIN_FAILED,
						payload: 'Login attempt not successful'
					});
					Cookies.remove('lexvid_token');
					reject();
				}
			})
			.catch(error => {
				dispatch({
					type: LOGIN_FAILED,
					payload: error
				});
				reject();
			});

	});
}

export const logout = () => dispatch => {
	dispatch({ type: LOGOUT });

	dispatch(unSetUser());

	dispatch({
		type: LOGOUT_SUCCESS,
		payload: null
	});
}

export const restoreUser = () => dispatch => {
	const token = Cookies.get('lexvid_token');

	const authState = store.getState().auth;
	// console.log(authState);

	// If Cookie IS set, then verify the user session is stored in redux. 
	// If not - retrieve member object and then persist in redux.
	if (!token) {
		dispatch(unSetUser());
	}
	// else if (token && (!authState || !authState.token || !authState.user || !authState.user.PaidCredits)) {
	else if (token && (!authState || !authState.token || !authState.user)) {
		AuthAPI
			.getMember(token)
			.then(response => {
				// console.log(response);
				if (response) {
					const user = response.data;
					dispatch(setUser(user, token));
				}
			})
			.catch(error => {
				console.log(error);
			});
	} else {
		dispatch(setUser(authState.user, token, false));
	}
}

// if no authenication cookie, create GUID and store in storage
export const setOfflineUser = () => dispatch => {

	let offline_guid = Cookies.get('lexvid_offline_guid');

	if (!offline_guid) {
		offline_guid = uuidv4();
		Cookies.set('lexvid_offline_guid', offline_guid, { expires: 30 });
	};

	axios.defaults.headers.common['Authorization'] = `Offline ${offline_guid}`;

	dispatch({
		type: SET_OFFLINE_USER,
		payload: offline_guid
	});
}

export const setUser = (user, token, call_set_user = true) => dispatch => {

	token = token ?? Cookies.get('lexvid_token');

	if (token) {
		// Add Authorization header to all axios requests
		axios.defaults.headers.common['Authorization'] = `Lexvid ${token}`;
	}

	if (call_set_user) {
		dispatch({
			type: SET_USER,
			payload: { user, token }
		});
	}

	Sentry.setUser({ email: user?.Email, id: user?.ID });
}

export const unSetUser = () => dispatch => {
	dispatch(setOfflineUser());

	Cookies.remove('lexvid_token', { path: '/', domain: '.lexvid.com' })

	// Remove cookie
	if (Cookies.get('lexvid_token')) {
		Cookies.remove('lexvid_token');

		// Delete Authorization header to all axios requests
		// delete axios.defaults.headers.common["Authorization"];
	}

	dispatch({ type: UNSET_USER });
	dispatch({ type: CLEAR_MEMBER });

	Sentry.setUser(null);
}

export const setAdmin = (isAdmin) => dispatch => {

	isAdmin ? dispatch({ type: SET_ADMIN }) : dispatch({ type: UNSET_ADMIN });

}

export const forgotPasswordLogin = (memberId, token) => dispatch => {
	return new Promise((resolve, reject) => {
		AuthAPI.forgotPasswordLogin(memberId, token).then(response => {
			if (response.data.IsSuccessful === true) {
				Cookies.set('lexvid_token', response.data.Identifier, { expires: 30 });

				const user = { ...response.data.Member };

				dispatch(setUser(user, response.data.Identifier));
			} 
			resolve(response.data);
		}).catch(error => {
			reject(error);
		});
	});
}

export const forgotPassword = email => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: FORGOT_PASSWORD });

		AuthAPI
			.forgotPassword(email)
			.then(response => {
				// Bad Email address will return 
				// { "MemberIdentifier": "00000000-0000-0000-0000-000000000000", "IsSuccessful": false, "ErrorMessage": "Unable to locate member", "StackTrace": "" }
				if (response.data.IsSuccessful) {
					dispatch({
						type: FORGOT_PASSWORD_SUCCESS,
						payload: {
							...response.data, email: email
						}
					});
					resolve();

				} else {
					dispatch({
						type: FORGOT_PASSWORD_FAILED,
						payload: response.data.ErrorMessage
					});
					reject();
				}
			})
			.catch(error => {
				dispatch({
					type: FORGOT_PASSWORD_FAILED,
					payload: error
				});
				reject(error);
			});
	});
}

export const updateUser = (user) => dispatch => {

	dispatch({ type: UPDATE_USER, payload: user });
}

export const checkout = (checkoutData, cartId) => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: CHECKOUT });
		const referrerCookie = Cookies.get('ReferredBy');
		checkoutData.ReferringMemberID = referrerCookie;
		AuthAPI
			.checkout(checkoutData, cartId)
			.then(response => {

				if (response.data?.IsAccountCreated === true) {
					Cookies.set('lexvid_token', response.data.Identifier, { expires: 30 });

					const user = { ...response.data.Member };

					dispatch(setUser(user, response.data.Identifier));

					dispatch({
						type: CHECKOUT_SUCCESS,
						payload: response.data
					});
					resolve(response.data);

				} else {
					dispatch({
						type: CHECKOUT_FAILED,
						payload: response.data.ErrorMessage || 'Checkout attempt not successful'
					});
					Cookies.remove('lexvid_token');
					reject();
				}
			})
			.catch(error => {
				dispatch({
					type: CHECKOUT_FAILED,
					payload: error
				});
				reject(error);
			});
	});
}

export const acceptCookies = () => dispatch => {
	Cookies.set('lexvid_cookies_accepted', true, { expires: 3650 });
}

export const checkCookiesAccepted = () => dispatch => {
	const cookiesAccepted = Cookies.get('lexvid_cookies_accepted');

	if (!cookiesAccepted) {
		dispatch({ type: SET_COOKIES_ACCEPTED, payload: false });
	} else {
		dispatch({ type: SET_COOKIES_ACCEPTED, payload: true });
	}
}