import { useEffect } from 'react';

export default function useFooterFixed(minHeight) {

    useEffect(() => {
        const footer = document.getElementsByClassName('footer-container');

        function handleResize() {
            const height = window.innerHeight;
            const width = window.innerWidth;
            // console.log(height);

            if (height > minHeight && width > 767 && footer && footer.length > 0 && !footer[0].classList.contains('fixed-bottom'))
                footer[0].classList.add('fixed-bottom');

            if ((height <= minHeight || width <= 767) && footer && footer.length > 0 && footer[0].classList.contains('fixed-bottom'))
                footer[0].classList.remove('fixed-bottom');
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return function cleanup() {
            // un-listen
            window.removeEventListener('resize', handleResize);

            // un-fix footer
            if (footer && footer.length > 0)
                footer[0].classList.remove('fixed-bottom');
        };

    }, [minHeight]);

}