// States
export const GET_STATES = 'GET_STATES';
export const GET_STATE = 'GET_STATE';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const RESET_STATE = 'RESET_STATE';
export const GET_STATE_CONTENT = 'GET_STATE_CONTENT';
export const GET_STATE_CONTENT_SUCCESS = 'GET_STATE_CONTENT_SUCCESS';
export const GET_STATE_CONTENT_ERROR = 'GET_STATE_CONTENT_ERROR';
export const UPDATE_STATE_URL = 'UPDATE_STATE_URL';
export const UPDATE_STATE_ID = 'UPDATE_STATE_ID';
export const GET_STATE_REQUIREMENTS = 'GET_STATE_REQUIREMENTS';
export const GET_STATE_REQUIREMENTS_SUCCESS = 'GET_STATE_REQUIREMENTS_SUCCESS';
export const GET_STATE_FAQS = 'GET_STATE_FAQS';
export const GET_STATE_FAQS_SUCCESS = 'GET_STATE_FAQS_SUCCESS';
export const GET_STATE_DISCOUNTS = 'GET_STATE_DISCOUNTS';
export const GET_STATE_DISCOUNTS_SUCCESS = 'GET_STATE_DISCOUNTS_SUCCESS';
export const UPDATE_MULTI_STATE_ARRAY = 'UPDATE_MULTI_STATE_ARRAY';
export const GET_MULTISTATE = 'GET_MULTISTATE';
export const GET_MULTISTATE_SUCCESS = 'GET_MULTISTATE_SUCCESS';
export const GET_DEFAULT_STATE_SUCCESS = 'GET_DEFAULT_STATE_SUCCESS';
export const GET_LIVE_COURSES_SUCCESS = 'GET_LIVE_COURSES_SUCCESS';

// Courses
export const GET_RECOMMENDED_COURSES_BY_ID = 'GET_RECOMMENDED_COURSES_BY_ID';
export const GET_POPULAR_STATE_COURSES = 'GET_POPULAR_STATE_COURSES';
export const GET_NEWEST_STATE_COURSES = 'GET_NEWEST_STATE_COURSES';
export const GET_SHORTEST_STATE_COURSES = 'GET_SHORTEST_STATE_COURSES';
export const GET_LONGEST_STATE_COURSES = 'GET_LONGEST_STATE_COURSES';
export const GET_PRACTICE_AREAS_BY_ID = 'GET_PRACTICE_AREAS_BY_ID';
export const GET_COURSE_ACCREDITATION = 'GET_COURSE_ACCREDITATION';
export const GET_COURSE_LISTING_CONTENT = 'GET_COURSE_LISTING_CONTENT';
export const GET_COURSES = 'GET_COURSES';
export const RESET_COURSES = 'RESET_COURSES';
export const GET_RECOMMENDED_COURSES_BY_URL = 'GET_RECOMMENDED_COURSES_BY_URL';
export const GET_PRACTICE_AREAS = 'GET_PRACTICE_AREAS';
export const GET_PLAYLIST_COURSE = 'GET_PLAYLIST_COURSE';
export const GET_COURSE_DETAILS_START = 'GET_COURSE_DETAILS_START';
export const GET_COURSE_DETAILS = 'GET_COURSE_DETAILS';
export const GET_COURSE_SLIDES = 'GET_COURSE_SLIDES';
export const GET_COURSE_FILES = 'GET_COURSE_FILES';
export const GET_COURSE_NOTES = 'GET_COURSE_NOTES';
export const SET_COURSE_NOTES = 'SET_COURSE_NOTES';
export const SET_COURSE_TRACKING = 'SET_COURSE_TRACKING';
export const CLEAR_COURSE = 'CLEAR_COURSE';
export const GET_COURSE_COMBINED_DATA = 'GET_COURSE_COMBINED_DATA';
export const GET_COURSE_PROGRESS = 'GET_COURSE_PROGRESS';
export const GET_COURSE_BY_ID = 'GET_COURSE_BY_ID';
export const GET_COURSES_COUNT = 'GET_COURSES_COUNT';
export const GET_COURSE_REVIEWS = 'GET_COURSE_REVIEWS';
export const GET_COURSE_REVIEWS_ALL = 'GET_COURSE_REVIEWS_ALL';
export const SET_PREVIEW_COURSE = 'SET_PREVIEW_COURSE';
export const RESET_PREVIEW_COURSE = 'RESET_PREVIEW_COURSE';
export const RESET_COURSE_LISTING_CONTENT = 'RESET_COURSE_LISTING_CONTENT';
export const GET_COURSE_ID = 'GET_COURSE_ID';
export const SET_COMPLETED_COURSE_ID = 'SET_COMPLETED_COURSE_ID';
export const GET_LIVE_COURSES_HOURS = 'GET_LIVE_COURSES_HOURS';

// Speakers
export const GET_SPEAKERS = 'GET_SPEAKERS';
export const GET_SPEAKERS_SHALLOW = 'GET_SPEAKERS_SHALLOW';
export const SEARCH_SPEAKERS = 'SEARCH_SPEAKERS';
export const UPDATE_SELECTED_SPEAKER_ID = 'UPDATE_SELECTED_SPEAKER_ID';
export const UPDATE_SELECTED_SPEAKER_URL = 'UPDATE_SELECTED_SPEAKER_URL';
export const GET_SPEAKER_BY_URL = 'GET_SPEAKER_BY_URL';
export const GET_SPEAKER_COURSES_BY_URL = 'GET_SPEAKER_COURSES_BY_URL';
export const GET_SPEAKER_REVIEWS = 'GET_SPEAKER_REVIEWS';
export const GET_SPEAKER_TESTIMONIALS = 'GET_SPEAKER_TESTIMONIALS';
export const SUBMIT_SPEAKER_QUESTION = 'SUBMIT_SPEAKER_QUESTION';
export const SUBMIT_SPEAKER_QUESTION_SUCCESS = 'SUBMIT_SPEAKER_QUESTION_SUCCESS';

// Bundles
export const GET_BUNDLE_CONTENT = 'GET_BUNDLE_CONTENT';
export const GET_BUNDLES = 'GET_BUNDLES';
export const GET_PACKAGES_CONTENT = 'GET_PACKAGES_CONTENT';
export const UPDATE_SELECTED_BUNDLE = 'UPDATE_SELECTED_BUNDLE';
export const UPDATE_SELECTED_BUNDLE_2 = 'UPDATE_SELECTED_BUNDLE_2';
export const UPDATE_PURCHASED_BUNDLE = 'UPDATE_PURCHASED_BUNDLE';
export const GET_PACKAGES_PRICING = 'GET_PACKAGES_PRICING';
export const UPDATE_SELECTED_LIVE_EVENTS = 'UPDATE_SELECTED_LIVE_EVENTS';
export const REMOVE_FROM_SELECTED_LIVE_EVENTS = 'REMOVE_FROM_SELECTED_LIVE_EVENTS';
export const SET_INCLUDE_SELECTED_LIVE_EVENTS = 'SET_INCLUDE_SELECTED_LIVE_EVENTS';
export const SET_CART_ID = 'SET_CART_ID';
export const UPDATE_IS_LIVE_COUPON_BAD = 'UPDATE_IS_LIVE_COUPON_BAD';
export const SET_LIVE_CART = 'SET_LIVE_CART';
export const REMOVE_FROM_LIVE_CART = 'REMOVE_FROM_LIVE_CART';
export const ADD_TO_TEMP_CART = 'ADD_TO_TEMP_CART';
export const CLEAR_TEMP_CART = 'CLEAR_TEMP_CART';
export const SET_COURSES_SELECTED_CART = 'SET_COURSES_SELECTED_CART';
export const SET_IS_FRACTIONAL_PRICE = 'SET_IS_FRACTIONAL_PRICE';
export const SET_SELECTED_FREE_LIVE_EVENT_IDS = 'SET_SELECTED_FREE_LIVE_EVENT_IDS';
export const SET_ONLY_FREE_LIVE_EVENTS = 'SET_ONLY_FREE_LIVE_EVENTS';

// Miscellaneous
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const GET_INCOMES = 'GET_INCOMES';
export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const GET_REFERRER = 'GET_REFERRER';
export const IS_EMAIL_VALID = 'IS_EMAIL_VALID';
export const IS_EMAIL_VALID_SUCCESS = 'IS_EMAIL_VALID_SUCCESS';
export const GET_REFERRAL_PAGE = 'GET_REFERRAL_PAGE';
export const SUBMIT_LAW_FIRM_INQUIRY = 'SUBMIT_LAW_FIRM_INQUIRY';
export const SUBMIT_LAW_FIRM_INQUIRY_SUCCESS = 'SUBMIT_LAW_FIRM_INQUIRY_SUCCESS';
export const SET_ADD_COURSE_AFTER_SIGNIN = 'SET_ADD_COURSE_AFTER_SIGNIN';
export const SUBMIT_SPEAKER_INQUIRY = 'SUBMIT_SPEAKER_INQUIRY';
export const SUBMIT_SPEAKER_INQUIRY_SUCCESS = 'SUBMIT_SPEAKER_INQUIRY_SUCCESS';
export const SET_REDIRECT_AFTER_SIGNIN = 'SET_REDIRECT_AFTER_SIGNIN';
export const CLEAR_REDIRECT_AFTER_SIGNIN = 'CLEAR_REDIRECT_AFTER_SIGNIN';
export const SET_WINDOW_DIMENSIONS = 'SET_WINDOW_DIMENSIONS';
export const SET_APP_ERROR = 'SET_APP_ERROR';

// AUTH
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const SET_OFFLINE_USER = 'SET_OFFLINE_USER';
export const GET_BARS = 'GET_BARS';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_RESET = 'SIGNUP_RESET';
export const SET_ADMIN = 'SET_ADMIN';
export const UNSET_ADMIN = 'UNSET_ADMIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const UPDATE_USER = 'UPDATE_USER';
export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILED = 'CHECKOUT_FAILED';
export const SET_COOKIES_ACCEPTED = 'SET_COOKIES_ACCEPTED';
export const REFRESH_USER = 'REFRESH_USER';
export const UPDATE_IS_PARALEGAL = 'UPDATE_IS_PARALEGAL';

// CONTENT
export const GET_PAGE_CONTENT = 'GET_PAGE_CONTENT';
export const GET_PAGE_CONTENT_SECONDARY = 'GET_PAGE_CONTENT_SECONDARY';
export const GET_MULTISTATE_CONTENT = 'GET_MULTISTATE_CONTENT';
export const GET_MULTISTATE_CONTENT_SUCCESS = 'GET_MULTISTATE_CONTENT_SUCCESS';
export const GET_MULTISTATE_PACKAGES_CONTENT = 'GET_MULTISTATE_PACKAGES_CONTENT';
export const GET_MULTISTATE_PACKAGES_CONTENT_SUCCESS = 'GET_MULTISTATE_PACKAGES_CONTENT_SUCCESS';
export const GET_DIDYOUKNOW_CONTENT = 'GET_DIDYOUKNOW_CONTENT';
export const GET_DIDYOUKNOW_CONTENT_SUCCESS = 'GET_DIDYOUKNOW_CONTENT_SUCCESS';
export const GET_TERMS_CONTENT_SUCCESS = 'GET_TERMS_CONTENT_SUCCESS';
export const RESET_CONTENT = 'RESET_CONTENT';
export const GET_IMAGE_META_DATA_SUCCESS = 'GET_IMAGE_META_DATA_SUCCESS';

// Member
export const GET_MEMBER_PLAYLIST = 'GET_MEMBER_PLAYLIST';
export const GET_MEMBER_PLAYLIST_SUCCESS = 'GET_MEMBER_PLAYLIST_SUCCESS';
export const GET_MEMBER_PLAYLIST_FAILED = 'GET_MEMBER_PLAYLIST_FAILED';
export const DELETE_PLAYLIST_COURSE = 'DELETE_PLAYLIST_COURSE';
export const DELETE_PLAYLIST_COURSE_SUCCESS = 'DELETE_PLAYLIST_COURSE_SUCCESS';
export const DELETE_PLAYLIST_COURSE_FAILED = 'DELETE_PLAYLIST_COURSE_FAILED';
export const ADD_PLAYLIST_COURSE = 'ADD_PLAYLIST_COURSE';
export const ADD_PLAYLIST_COURSE_SUCCESS = 'ADD_PLAYLIST_COURSE_SUCCESS';
export const ADD_PLAYLIST_COURSE_FAILED = 'ADD_PLAYLIST_COURSE_FAILED';
export const GET_MEMBER_DEADLINES = 'GET_MEMBER_DEADLINES';
export const GET_MEMBER_DEADLINES_SUCCESS = 'GET_MEMBER_DEADLINES_SUCCESS';
export const GET_COMPLETED_COURSES = 'GET_COMPLETED_COURSES';
export const GET_COMPLETED_COURSES_SUCCESS = 'GET_COMPLETED_COURSES_SUCCESS';
export const GET_MEMBER_COMPLIANCE = 'GET_MEMBER_COMPLIANCE';
export const GET_MEMBER_COMPLIANCE_SUCCESS = 'GET_MEMBER_COMPLIANCE_SUCCESS';
export const GET_MEMBER_PERIODS = 'GET_MEMBER_PERIODS';
export const GET_MEMBER_PERIODS_SUCCESS = 'GET_MEMBER_PERIODS_SUCCESS';
export const MOVE_MEMBER_CREDITS = 'MOVE_MEMBER_CREDITS';
export const MOVE_MEMBER_CREDITS_SUCCESS = 'MOVE_MEMBER_CREDITS_SUCCESS';
export const GET_MEMBER_TRANSACTIONS = 'GET_MEMBER_TRANSACTIONS';
export const GET_MEMBER_TRANSACTIONS_SUCCESS = 'GET_MEMBER_TRANSACTIONS_SUCCESS';
export const ADD_MEMBER_PRACTICE_AREA = 'ADD_MEMBER_PRACTICE_AREA';
export const ADD_MEMBER_PRACTICE_AREA_SUCCESS = 'ADD_MEMBER_PRACTICE_AREA_SUCCESS';
export const DELETE_MEMBER_PRACTICE_AREA = 'DELETE_MEMBER_PRACTICE_AREA';
export const DELETE_MEMBER_PRACTICE_AREA_SUCCESS = 'DELETE_MEMBER_PRACTICE_AREA_SUCCESS';
export const EDIT_MEMBER_SHALLOW = 'EDIT_MEMBER_SHALLOW';
export const EDIT_MEMBER_SHALLOW_SUCCESS = 'EDIT_MEMBER_SHALLOW_SUCCESS';
export const CLEAR_MEMBER = 'CLEAR_MEMBER';
export const UPDATE_MEMBER_BAR = 'UPDATE_MEMBER_BAR';
export const UPDATE_MEMBER_BAR_SUCCESS = 'UPDATE_MEMBER_BAR_SUCCESS';
export const DELETE_MEMBER_BAR = 'DELETE_MEMBER_BAR_SUCCESS';
export const DELETE_MEMBER_BAR_SUCCESS = 'DELETE_MEMBER_BAR_SUCCESS';
export const SET_MEMBER_RECEIPT = 'SET_MEMBER_RECEIPT';
export const GET_DEADLINE_CONFIG = 'GET_DEADLINE_CONFIG';
export const GET_DEADLINE_CONFIG_SUCCESS = 'GET_DEADLINE_CONFIG_SUCCESS';
export const GET_MEMBER_REFERRAL = 'GET_MEMBER_REFERRAL';
export const GET_MEMBER_REFERRAL_SUCCESS = 'GET_MEMBER_REFERRAL_SUCCESS';
export const GET_MEMBER_NOTIFICATIONS = 'GET_MEMBER_NOTIFICATIONS';
export const MEMBER_PAGE_REQUEST = 'MEMBER_PAGE_REQUEST';
export const EDIT_MEMBER_CONTACT = 'EDIT_MEMBER_CONTACT';
export const EDIT_MEMBER_CONTACT_SUCCESS = 'EDIT_MEMBER_CONTACT_SUCCESS';
export const CHECK_MEMBER_LIVE_COURSE = 'CHECK_MEMBER_LIVE_COURSE';


// Account 
export const GET_PRODUCT_PRICE = 'GET_PRODUCT_PRICE';
export const GET_PRODUCTS_PRICE = 'GET_PRODUCTS_PRICE';
export const PURCHASE_PRODUCT = 'PURCHASE_PRODUCT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const RESET_PURCHASE_PRODUCT = 'RESET_PURCHASE_PRODUCT';
export const RESET_PURCHASE_PRODUCTS = 'RESET_PURCHASE_PRODUCTS';
export const RESET_ERROR_MESSAGE_COUPON = 'RESET_ERROR_MESSAGE_COUPON';
export const COMPLETE_LIVE_COURSE = 'COMPLETE_LIVE_COURSE';
export const COMPLETE_LIVE_COURSE_FAILED = 'COMPLETE_LIVE_COURSE_FAILED';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

// Blog
export const GET_BLOG_CATEGORIES = 'GET_BLOG_CATEGORIES';
export const SET_BLOG_SELECTED_CATEGORY = 'SET_BLOG_SELECTED_CATEGORY';
export const GET_BLOG_POSTS = 'GET_BLOG_POSTS';