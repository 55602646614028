import {
	GET_PAGE_CONTENT,
	GET_MULTISTATE_CONTENT_SUCCESS,
	GET_MULTISTATE_PACKAGES_CONTENT_SUCCESS,
	GET_DIDYOUKNOW_CONTENT_SUCCESS,
	RESET_CONTENT,
	GET_STATE_CONTENT,
	GET_STATE_CONTENT_SUCCESS,
	GET_STATE_CONTENT_ERROR,
	GET_IMAGE_META_DATA_SUCCESS,
	GET_PAGE_CONTENT_SECONDARY
} from '../actions/types';

const initialState = {
	content: {
		ContentBlock: {
			'Complete CLE Hours': {
				Content: '',
				Header: '',
				SubHeader: ''
			},
			'Curated Online Subtitle': {
				Content: ''
			},
			'CLE Requirements': {
				Content: ''
			},
			'Curated Online Content': {
				Content: '',
				Header: ''
			}
		}
	},
	multiStateContent: {},
	multiStatePackagesContent: {},
	secondaryContent: {},
	didYouKnowContent: [],
	imageMetaData: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PAGE_CONTENT:
			{
				return {
					...state,
					content: action.payload
				}
			}
		case GET_PAGE_CONTENT_SECONDARY:
			{
				return {
					...state,
					secondaryContent: action.payload
				}
			}
		case GET_MULTISTATE_CONTENT_SUCCESS:
			{
				return {
					...state,
					multiStateContent: action.payload,
					content: action.payload
				}
			}
		case GET_MULTISTATE_PACKAGES_CONTENT_SUCCESS:
			{
				return {
					...state,
					multiStatePackagesContent: action.payload,
					multiStateContent: { StateContentBlock: action.payload.StateContentBlock },
					content: action.payload
				}
			}
		case GET_DIDYOUKNOW_CONTENT_SUCCESS:
			{
				return {
					...state,
					didYouKnowContent: action.payload
				}
			}
		case RESET_CONTENT:
			{
				return {
					...state,
					content: null
				}
			}
		case GET_STATE_CONTENT:
			{
				return {
					...state
				}
			}
		case GET_STATE_CONTENT_SUCCESS:
			{
				return {
					...state,
					content: action.payload
				}
			}
		case GET_STATE_CONTENT_ERROR:
			{
				return {
					...state,
					error: action.payload
				}
			}
		case GET_IMAGE_META_DATA_SUCCESS:
			{
				return {
					...state,
					imageMetaData: action.payload
				}
			}
		default:
			return state;
	}
}