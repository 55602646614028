import React from 'react';
import { Helmet } from 'react-helmet';

const OrganizationDataMarkup = ({courseTitle, courseDescription, courseUrl, authors}) => {
  return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "https://schema.org",
					"@type": "Organization",
					"url": "https://lexvid.com",
      		"sameAs": ["https://www.facebook.com/lexvidcle", "https://www.linkedin.com/company/lexvid", "https://www.youtube.com/channel/UCKmbK6KRKVb1C2rQqcv7kAw", "https://twitter.com/lexvid"],
      		"name": "LexVid",
      		"description": "LexVid is a high quality, affordable provider of CLE since 2009. Our on demand and live courses cover the latest legal topics and emerging issues, along with the fundamentals.",
					"email": "support@lexvid.com",
      		"telephone": "+1-877-327-1226",
				})}
			</script>
		</Helmet>
  );
}

export default OrganizationDataMarkup;