import React from 'react';
import './Compliance.scss';
import { Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Compliance = (props) => {

	const complianceData = props.data;
	const paidStatesWithDeadline = complianceData?.filter(data => data?.Period?.IsPaidState && !data?.HideComplianceBar)

	return (
		<div className='compliance-subheader-wrap'>

			<Carousel indicators={false} controls={false} interval={5000} fade={true} className='state-compliance-wrap'>


				{paidStatesWithDeadline?.map((stateCompliance, index) => {

					const year = (new Date(stateCompliance.Period.EndDate)).getFullYear();
					const specialty = stateCompliance.Compliance?.Specialty;

					const specialtiesCompletedHours = Object.keys(specialty).map((key, index) => {
						return <span key={key} className='specialty-wrap'>
							{key}: {specialty[key].CompletedHours}/{specialty[key].RequiredHours}{index + 1 !== Object.keys(specialty).length ? ', ' : ''}
						</span>;
					});

					return <Carousel.Item key={index}>
						<div className='summary-wrap'>
							<span className='font-weight-bold'>
								My {stateCompliance.Period.StateName} {year} Compliance Summary:&nbsp;
								{stateCompliance.Compliance?.CompletedHours}/{stateCompliance.Compliance?.RequiredHours}&nbsp;
							</span>
							{stateCompliance.Period.StateID === 11 || stateCompliance.Period.StateID === 15 ? 
								<Link to={`/secure/completed/${stateCompliance.Period.FriendlyURL}`}>View Full Compliance Summary</Link> :
								<>({specialtiesCompletedHours})</>}
						</div>
						<div className='my-playlist'>My Playlist: {stateCompliance.PlaylistCredits} Hours</div>
					</Carousel.Item>;
				})}

			</Carousel>

		</div>
	);
}

export default Compliance;