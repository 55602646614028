import axios from '../utils/axiosInterceptors';
import Cookies from "js-cookie";
import { isEmpty } from 'lodash';
const baseURL = process.env.REACT_APP_LEXVID_API;

const getProductLivePrice = (productInfo) => {
	const URL = `${baseURL}/api/Account/PriceLive`;

	const productPrice = axios({
		method: 'POST',
		url: URL,
		data: productInfo
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return productPrice;
}

const getProductPrice = (productInfo) => {
	const URL = `${baseURL}/api/Account/Price`;

	const productPrice = axios({
		method: 'POST',
		url: URL,
		data: productInfo
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return productPrice;
}


const getProductPrice2 = (productInfo) => {
	const URL = `${baseURL}/api/Account/PriceLiveOd`;

	const productPrice = axios({
		method: 'POST',
		url: URL,
		data: productInfo
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return productPrice;
}

const purchaseProduct = (productInfo, cartId) => {
	let URL = '';
	if (cartId) URL = `${baseURL}/api/Account/Create/LiveEvents/${cartId}`;
	else URL = `${baseURL}/api/Account/CreateLiveOd`;

	const referrerCookie = Cookies.get('ReferredBy');
	productInfo.ReferringMemberID = referrerCookie;
	const purchaseProduct = axios({
		method: 'POST',
		url: URL,
		data: productInfo
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return purchaseProduct;
}

const changePassword = (memberIdentifier, password, confirmPassword) => {
	const URL = `${baseURL}/api/Account/ChangePassword`;

	const response = axios({
		method: 'POST',
		url: URL,
		data: {
			"MemberIdentifier": memberIdentifier,
			"Password": password,
			"ConfirmPassword": confirmPassword,
		}
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return response;
}

const completeLiveCourse = (liveEventAttendeeID, completionCode) => {
	const URL = `${baseURL}/api/course/LiveCourses/CompleteLiveCourse/${liveEventAttendeeID}/${completionCode}`;

	const response = axios({
		method: 'POST',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return response;	
}

const rescheduleLiveCourse = (LiveEventAttendeeID, newLiveEventId) => {
	const URL = `${baseURL}/api/course/LiveCourses/RescheduleLiveCourse/${LiveEventAttendeeID}/${newLiveEventId}`;

	const response = axios({
		method: 'POST',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return response;	
}

const addProductToLiveCart = (product, cartId, memberId) => {
	let URL = `${baseURL}/api/product/shoppingcart/addproduct`;

	if (cartId) URL += `/${cartId}`;

	if (memberId) URL += `/${memberId}`;

	const response = axios({
		method: 'POST',
		url: URL,
		data: product
	}).then((response) => {
		if (response.status === 200) {
			return response.data;
		}
	}).catch((error) => {
		console.log(error);
	});

	return response;
}

const removeProductFromLiveCart = (cartId, liveEventsId) => {
	if (!cartId || isEmpty(liveEventsId)) return;

	const URL = `${baseURL}/api/product/shoppingcart/removeproduct/${cartId}/${liveEventsId}`;

	const response = axios({
		method: 'DELETE',
		url: URL
	}).then((response) => {
		if (response.status === 200) {
			return response.data;
		}

	}).catch((error) => {
		console.log(error);
	});

	return response;
}

const getLiveCart = (cartId, isFractionalPrice) => {
	let URL = `${baseURL}/api/product/shoppingcart/getcart/${cartId}`;

	if (isFractionalPrice) URL += `?isFractionalPrice=1`;

	const response = axios({
		method: 'GET',
		url: URL
	}).then((response) => {
		if (response.status === 200) {
			return response.data;
		}
	}).catch((error) => {
		console.log(error);
	});

	return response;
}

const clearLiveCart = (cartId) => {

	if (cartId === null) return Promise.resolve(true);

	let URL = `${baseURL}/api/product/shoppingcart/clearcart/${cartId}`;

	const response = axios({
		method: 'GET',
		url: URL
	}).then((response) => {
		if (response.status === 200) {
			return response.data;
		}
	}).catch((error) => {
		console.log(error);
	});

	return response;
}



const getLiveCartPrice = (cartId, couponCode, alreadyAppliedDiscount, isFractionalPrice) => {
	let URL = `${baseURL}/api/Account/LiveCoupon/${cartId}`;

	if (alreadyAppliedDiscount >= 0	) URL += `/${alreadyAppliedDiscount}/`;
	else URL += '/0';

	if (isFractionalPrice) URL += `?isFractionalPrice=1`;

	const response = axios({
		method: 'POST',
		url: URL,
		data: { CouponCode: couponCode }
	}).then((response) => {
		if (response.status === 200) {
			return response.data;
		}
	}).catch((error) => {
		console.log(error);
	});

	return response;
}

const sendStartedCheckout = (accountModel) => {
	const URL = `${baseURL}/api/Account/Klaviyo/StartedCheckout`;

	const response = axios({
		method: 'POST',
		url: URL,
		data: accountModel
	}).then((response) => {
		if (response.status === 200) {
			return response.data;
		}
	}).catch((error) => {
		console.log(error);
	});

	return response;
}

export default {
	getProductLivePrice,
	getProductPrice,
	getProductPrice2,
	purchaseProduct,
	changePassword,
	completeLiveCourse,
	rescheduleLiveCourse,
	addProductToLiveCart,
	removeProductFromLiveCart,
	getLiveCartPrice,
	getLiveCart,
	clearLiveCart,
	sendStartedCheckout
}