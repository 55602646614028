import {
	GET_MULTISTATE_CONTENT,
	GET_MULTISTATE_CONTENT_SUCCESS,
	GET_MULTISTATE_PACKAGES_CONTENT,
	GET_MULTISTATE_PACKAGES_CONTENT_SUCCESS,
	GET_PAGE_CONTENT,
	GET_DIDYOUKNOW_CONTENT,
	GET_DIDYOUKNOW_CONTENT_SUCCESS,
	RESET_CONTENT,
	GET_STATE_CONTENT,
	GET_STATE_CONTENT_SUCCESS,
	GET_IMAGE_META_DATA_SUCCESS,
	GET_PAGE_CONTENT_SECONDARY
} from './types';
import ContentAPI from '../api/ContentAPI';
import MiscAPI from '../api/MiscAPI';
import Cookies from 'js-cookie';

export const getPageContent = (pageName, stateURL, asSecondaryContent = false) => dispatch => {
	// The content call will become {{LexVid-EndPoint}}/api/Content/Packages/california-mcle?campaign=1
	// TODO: Signed out only
	const campaignCookie = Cookies.get('campaign')
	const referredBy = Cookies.get('ReferredBy')
	const isAdmin = Cookies.get('admin-id')

	ContentAPI
		.getPageContent(pageName, stateURL, campaignCookie, referredBy, isAdmin)
		.then(data => {
			if (asSecondaryContent) {
				dispatch({ type: GET_PAGE_CONTENT_SECONDARY, payload: data });
			} else {
				dispatch({ type: GET_PAGE_CONTENT, payload: data });
			}
		})
		.catch((error) => {
			console.log(error);
		})

}

export const getSpeakersPageContent = (searchSpecialty, currentPage, totalPages) => dispatch => {
	return new Promise((resolve, reject) => {

		ContentAPI
			.getSpeakersContent(searchSpecialty, currentPage, totalPages)
			.then((content) => {
				dispatch({ type: GET_PAGE_CONTENT, payload: content });
				resolve(content);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}


export const getPageContentSecondary = (pageName, stateURL) => getPageContent(pageName, stateURL, true)


export const getCourseReviewPageContent = (courseURL) => dispatch => {
	const isAdmin = Cookies.get('admin-id')

	ContentAPI
		.getCourseReviewPageContent(courseURL, isAdmin)
		.then(data => {
			dispatch({ type: GET_PAGE_CONTENT_SECONDARY, payload: data });
		})
		.catch((error) => {
			console.log(error);
		})

}

export const getMultiStateContent = (stateIDs) => dispatch => {
	dispatch({ type: GET_MULTISTATE_CONTENT });
	const referredBy = Cookies.get('ReferredBy')

	ContentAPI
		.getMultiStateContent(stateIDs, referredBy)
		.then((multiStateContent) => {
			dispatch({ type: GET_MULTISTATE_CONTENT_SUCCESS, payload: multiStateContent });
		})
		.catch((error) => {
			console.log(error);
		});
}

export const getMultiStatePackagesContent = (stateIDs) => dispatch => {
	dispatch({ type: GET_MULTISTATE_PACKAGES_CONTENT });

	const campaignCookie = Cookies.get('campaign')
	const referredBy = Cookies.get('ReferredBy')
	ContentAPI
		.getMultiStatePackagesContent(stateIDs, campaignCookie, referredBy)
		.then((multiStatePackagesContent) => {
			dispatch({ type: GET_MULTISTATE_PACKAGES_CONTENT_SUCCESS, payload: multiStatePackagesContent });
		})
		.catch((error) => {
			console.log(error);
		})

}

export const getDidYouKnowContent = () => dispatch => {
	dispatch({ type: GET_DIDYOUKNOW_CONTENT });

	return new Promise((resolve, reject) => {
		ContentAPI
			.getDidYouKnowContent()
			.then((didYouKnowContent) => {
				dispatch({ type: GET_DIDYOUKNOW_CONTENT_SUCCESS, payload: didYouKnowContent });
				resolve();
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	})
}

export const getTermsContent = () => dispatch => {

	return new Promise((resolve, reject) => {
		MiscAPI
			.getTermsContent()
			.then((termsContent) => {
				dispatch({ type: GET_PAGE_CONTENT, payload: termsContent });
				resolve();
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}

export const resetContent = () => dispatch => {

	dispatch({ type: RESET_CONTENT });
}

export const getStateContent = (stateURL) => dispatch => {
	dispatch({ type: GET_STATE_CONTENT });

	const campaignCookie = Cookies.get('campaign')
	const isAdmin = Cookies.get('admin-id')

	return new Promise((resolve, reject) => {
		ContentAPI
			.getStateContent(stateURL, campaignCookie, isAdmin)
			.then((stateContent) => {
				dispatch({ type: GET_STATE_CONTENT_SUCCESS, payload: stateContent });
				resolve();
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}

export const getLiveCourseContent = (stateId) => dispatch => {
	return new Promise((resolve, reject) => {
		ContentAPI
			.getLiveCourseContent(stateId)
			.then((liveCourseContent) => {
				dispatch({ type: GET_PAGE_CONTENT, payload: liveCourseContent });
				resolve(liveCourseContent);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}

export const getImageMetaData = () => dispatch => {

	return new Promise((resolve, reject) => {
		ContentAPI
			.getImageMetaData()
			.then((response) => {
				dispatch({ type: GET_IMAGE_META_DATA_SUCCESS, payload: response })
				resolve();
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}