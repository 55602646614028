import React, { useState } from 'react';
import EnterLiveEventCodeModal from "../SecureHome/components/EnterLiveEventCodeModal";
import { Button } from "react-bootstrap";

const LiveCourseBanner = ({ liveCourse }) => {

	const upcoming = liveCourse?.Upcoming === true;
	const inProgress = liveCourse?.InProgress === true;
	const over = liveCourse?.CourseOver === true;

	const upcomingOrInProgress = upcoming || inProgress;

	const [hide, setHide] = useState(false);
	const [showEnterCodeModal, setShowEnterCodeModal] = useState(false);

	const joinNowButton = <a target="_blank" className="btn btn-primary" rel="noopener noreferrer" 
		href={liveCourse?.ZoomLink}>Join Now</a>;

	const enterCodeButton = 
		<Button variant="primary" onClick={() => setShowEnterCodeModal(true)}>
			Enter Attendance Code
		</Button>;


	return <div className="live-course-banner-wrap">
		<div className="title-line">
			<div className="title">
				{upcoming ? "Your Live Course Is Starting Soon" : null}
				{inProgress ? "Your Live Course Is In Progress" : null}
				{over ? "Your Live Course Has Ended" : null}
			</div>

			{upcomingOrInProgress && hide ? joinNowButton : null}
			{over && hide ? enterCodeButton : null}
			
			<Button className="hide-show" variant="link" onClick={() => setHide(!hide)}>{hide ? "Show" : "Hide"}</Button>
		</div>

		{!hide ? 
			<div className="content">
				{upcomingOrInProgress ? joinNowButton : null}
				{over ? enterCodeButton : null}
				<div>
					<div className="line-1">{liveCourse?.TitleDate}</div>
					{!over ? 
						<div className="line-2">
							<span>
								<b>Meeting ID: </b>{liveCourse?.MeetingID}
							</span>
							
							<span>
								<b>Passcode: </b>{liveCourse?.Passcode}
							</span>

							<span>
								<b>Phone #: </b>{liveCourse?.ZoomNumber}
							</span>
						</div>
					: null}
				</div>
			</div>
		: null}

		<EnterLiveEventCodeModal 
			show={showEnterCodeModal}
        	handleClose={() => setShowEnterCodeModal(false)}
			liveEvent={liveCourse} />

	</div>
}

export default LiveCourseBanner;
