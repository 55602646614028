import { isEmpty } from 'lodash';
import { serialize } from 'tinyduration';

const getContentGroup = (path) => {
	let contentGroup = '';


	switch (path) {
		case '/':
			contentGroup = 'Home Page Signed Out';
			break;
		default:
			// -- Signed Out --
			//
			let regex = /.\/requirements$/;
			if (regex.test(path)) {
				contentGroup = 'State Requirements Signed Out';
				break;
			}

			// State Pages (Paid)
			regex = /^\/(new-york|california|texas|florida|arizona|nevada|hawaii|tennessee|vermont|new-jersey|north-dakota|missouri|alaska|colorado|washington|ohio|pennsylvania|connecticut|virginia|illinois|georgia|minnesota|mississippi|north-carolina|alabama|iowa|louisiana|nebraska|oklahoma|new-mexico|arkansas|idaho|indiana|south-carolina|wyoming|maine|rhode-island|utah|west-virginia|deleware|new-hampshire|montana|kentucky|kansas)-m?cle(\?gad=1)?$/;
			if (regex.test(path)) {
				contentGroup = 'State Pages (Paid)';
				break;
			}

			// State Page Multi-state
			regex = /^\/multi-state-cle$/;
			if (regex.test(path)) {
				contentGroup = 'State Page Multi-state';
				break;
			}
			
			// Financial Aid
			regex = /^\/financial-aid-cle$/;
			if (regex.test(path)) {
				contentGroup = 'Financial Aid';
				break;
			}

			// Law Firm
			regex = /^\/law-firm-cle$/;
			if (regex.test(path)) {
				contentGroup = 'Law Firm';
				break;
			}

			// State Page Free CLE
			regex = /^\/([a-zA-Z-]+)-m?cle\/free$/;
			if (regex.test(path)) {
				contentGroup = 'State Page Free CLE';
				break;
			}
			regex = /^\/([a-zA-Z-]+)-m?cle\/free\/1000$/;
			if (regex.test(path)) {
				contentGroup = 'State Page Free CLE';
				break;
			}

			// Browse Courses by Specialty no state Signed Out
			regex = /^\/courses\/([a-zA-Z-]+)\/?$/;
			if (regex.test(path)) {
				contentGroup = 'Browse Courses by Specialty no state Signed Out';
				break;
			}


			// Browse Courses by Specialty Multi-state Signed Out
			regex = /^\/multi-state-cle\/courses\/([a-zA-Z-]+)\/?$/;
			if (regex.test(path)) {
				contentGroup = 'Browse Courses by Specialty Multi-state Signed Out';
				break;
			}

			// Browse Courses Multi-state Signed Out
			regex = /^\/multi-state-cle\/courses\/?$/;
			if (regex.test(path)) {
				contentGroup = 'Browse Courses Multi-state Signed Out';
				break;
			}

			// Browse Courses Signed Out
			regex = /^\/([a-zA-Z-]+)-m?cle\/courses\/?$/;
			if (regex.test(path) || path === '/courses' || path === '/cle-courses') {
				contentGroup = 'Browse Courses Signed Out';
				break;
			}

			// Browse Courses by Specialty Signed Out
			regex = /^\/([a-zA-Z-]+)-m?cle\/courses\/([a-zA-Z-]+)$/;
			if (regex.test(path)) {
				contentGroup = 'Browse Courses by Specialty Signed Out';
				break;
			}
			regex = /^\/([a-zA-Z-]+)-m?cle\/courses\/([a-zA-Z-]+)\/1000$/;	
			if (regex.test(path)) {
				contentGroup = 'Browse Courses by Specialty Signed Out';
				break;
			}

			// State Page Multi-state Free CLE
			regex = /^\/multi-state-cle\/free$/;
			if (regex.test(path)) {
				contentGroup = 'State Page Multi-state Free CLE';
				break;
			}


			// Course Reviews Signed Out
			regex = /^\/cle-course\/.*\/reviews$/;
			if (regex.test(path)) {
				contentGroup = 'Course Reviews Signed Out';
				break;
			}

			// Course Detail Signed Out	
			regex = /^\/cle-course\/.*$/;
			if (regex.test(path)) {
				contentGroup = 'Course Detail Signed Out';
				break;
			}

			// Packages Multi-state Signed Out	
			regex = /^\/multi-state-cle\/packages$/;
			if (regex.test(path)) {
				contentGroup = 'Packages Multi-state Signed Out';
				break;
			}

			// Product Signed In
			regex = /^\/secure\/cle-packages\/.*/;
			if (regex.test(path)) {
				contentGroup = 'Product Signed In';
				break;
			}
			// /secure/packages/custom
			regex = /^\/secure\/packages\/custom$/;
			if (regex.test(path)) {
				contentGroup = 'Product Signed In';
				break;
			}
			// /secure/packages/bundle
			regex = /^\/secure\/packages\/bundle$/;
			if (regex.test(path)) {
				contentGroup = 'Product Signed In';
				break;
			}
			// /secure/packages/unlimited
			regex = /^\/secure\/packages\/unlimited$/;
			if (regex.test(path)) {
				contentGroup = 'Product Signed In';
				break;
			}
			// /secure/multi-state-cle/packages/{any word here}
			regex = /^\/secure\/multi-state-cle\/packages\/.*/;
			if (regex.test(path)) {
				contentGroup = 'Product Signed In';
				break;
			}

			// Product Signed Out	
			regex = /.*-m?cle\/packages\/.*/;
			if (regex.test(path)) {
				contentGroup = 'Product Signed Out';
				break;
			}

			// Payment Signed Out	
			regex = /^\/check-out$/;
			if (regex.test(path)) {
				contentGroup = 'Payment Signed Out';
				break;
			}

			// Sign In	
			regex = /^\/sign(-|)in($|\/$)/;
			if (regex.test(path)) {
				contentGroup = 'Sign In';
				break;
			}
			regex = /^\/sign-in\/login\/?$/;
			if (regex.test(path)) {
				contentGroup = 'Sign In';
				break;
			}

			// Sign Up	
			regex = /^\/sign-up\/?$/;
			if (regex.test(path)) {
				contentGroup = 'Sign Up';
				break;
			}

			// Sign Up Address	/signup/address.*
			regex = /\/signup\/address.*/;
			if (regex.test(path)) {
				contentGroup = 'Sign Up Address';
				break;
			}

			// Forgot Password	^/forgot-password$
			regex = /^\/forgot-password$/;
			if (regex.test(path)) {
				contentGroup = 'Forgot Password';
				break;
			}
			
			// Forgot Password Confirmation
			regex = /^\/forgot-password\/confirmation$/;
			if (regex.test(path)) {
				contentGroup = 'Forgot Password Confirmation';
				break;
			}
			regex = /^\/forgot-password-confirmation$/;
			if (regex.test(path)) {
				contentGroup = 'Forgot Password Confirmation';
				break;
			}

			// Referral Program Signed Out
			regex = /^\/cle-referral-program$/;
			if (regex.test(path)) {
				contentGroup = 'Referral Program Signed Out';
				break;
			}


			// -- Signed In --
			// Home Page Signed In
			regex = /^\/secure$/;
			if (regex.test(path)) {
				contentGroup = 'Home Page Signed In';
				break;
			}
			regex = /^\/secure\/$/;
			if (regex.test(path)) {
				contentGroup = 'Home Page Signed In';
				break;
			}

			// State Requirements Signed In
			regex = /^\/secure\/requirements.*/;
			if (regex.test(path)) {
				contentGroup = 'State Requirements Signed In';
				break;
			}

			// My Account
			regex = /^\/secure\/my-account\/?$/;
			if (regex.test(path)) {
				contentGroup = 'My Account';
				break;
			}

			// Completed Courses and Certificates
			regex = /^\/secure\/completed.*/;
			if (regex.test(path)) {
				contentGroup = 'Completed Courses and Certificates';
				break;
			}

			// My Live Courses
			regex = /^\/secure\/live$/;
			if (regex.test(path)) {
				contentGroup = 'My Live Courses';
				break;
			}

			// Packages Single State Signed In - Live
			regex = /^\/secure\/packages\/live$/;
			if (regex.test(path)) {
				contentGroup = 'Packages Single State Signed In - Live';
				break;
			}


			// My Bundle Courses
			regex = /^\/secure\/bundle\/\d+/;
			if (regex.test(path)) {
				contentGroup = 'My Bundle Courses';
				break;
			}

			// My Playlist
			regex = /^\/secure\/playlist$/;
			if (regex.test(path)) {
				contentGroup = 'My Playlist';
				break;
			}

			// Referral Program Signed In
			regex = /^\/secure\/cle-referral-program$/;
			if (regex.test(path)) {
				contentGroup = 'Referral Program Signed In';
				break;
			}

			// Browse Courses Signed In
			regex = /^\/secure\/([a-zA-Z-]+)-m?cle\/courses\/?$/;
			if (regex.test(path) || path === '/secure/courses' || path === '/secure/courses/') {
				contentGroup = 'Browse Courses Signed In';
				break;
			}	

			// Browse Courses by Specialty Signed In
			regex = /^\/secure\/courses\/([a-zA-Z-]+)$/;
			if (regex.test(path)) {
				contentGroup = 'Browse Courses by Specialty Signed In';
				break;
			}

			// Course Reviews Signed In
			regex = /^\/secure\/cle-course\/.*\/reviews$/;
			if (regex.test(path)) {
				contentGroup = 'Course Reviews Signed In';
				break;
			}

			// Course Detail Signed In
			regex = /^\/secure\/cle-course\/.*$/;
			if (regex.test(path)) {
				contentGroup = 'Course Detail Signed In';
				break;
			}
			
			// Course Evaluation
			regex = /^\/secure\/cle-course\/.*\/evaluation$/;
			if (regex.test(path)) {
				contentGroup = 'Course Evaluation';
				break;
			}
			
			// Course Timeout
			regex = /^\/secure\/cle-course\/.*\/timeout.*$/;
			if (regex.test(path)) {
				contentGroup = 'Course Timeout';
				break;
			}
			
			// Packages Multi-state Signed In
			regex = /^\/secure\/multi-state-cle\/packages$/;
			if (regex.test(path)) {
				contentGroup = 'Packages Multi-state Signed In';
				break;
			}

			// Packages Single State Signed In
			regex = /^\/secure\/cle-packages$/;
			if (regex.test(path)) {
				contentGroup = 'Packages Single State Signed In';
				break;
			}
			// /secure/packages
			regex = /^\/secure\/packages$/;
			if (regex.test(path)) {
				contentGroup = 'Packages Single State Signed In';
				break;
			}
			
			
			// Payment Signed In
			regex = /^\/secure\/payment($|\?product.*)/;
			if (regex.test(path)) {
				contentGroup = 'Payment Signed In';
				break;
			}
			
			
			// -- Signed out that MUST be after signed in --
			// Packages Single State Signed Out	
			regex = /.*cle\/packages$/;
			if (regex.test(path)) {
				contentGroup = 'Packages Single State Signed Out';
				break;
			}
			regex = /^\/cle-packages$/;
			if (regex.test(path)) {
				contentGroup = 'Packages Single State Signed Out';
				break;
			}
			

			// -- General --
			// Speaker
			regex = /^\/cle-speakers\/.*/;
			if (regex.test(path)) {
				contentGroup = 'Speaker';
				break;
			}

			// About
			regex = /^\/about$/;
			if (regex.test(path)) {
				contentGroup = 'About';
				break;
			}

			// Contact
			regex = /^\/contact$/;
			if (regex.test(path)) {
				contentGroup = 'Contact';
				break;
			}

			// Terms
			regex = /^\/terms-of-use$/;
			if (regex.test(path)) {
				contentGroup = 'Terms';
				break;
			}

			// Privacy
			regex = /^\/privacy-policy$/;
			if (regex.test(path)) {
				contentGroup = 'Privacy';
				break;
			}

			// Speakers
			regex = /^\/cle-speakers$/;
			if (regex.test(path)) {
				contentGroup = 'Speakers';
				break;
			}

			// Become a Faculty
			regex = /^\/cle-speaker\/application$/;
			if (regex.test(path)) {
				contentGroup = 'Become a Faculty';
				break;
			}

			// Customer Reviews
			regex = /^\/customer-reviews$/;
			if (regex.test(path)) {
				contentGroup = 'Customer Reviews';
				break;
			}

			// Mobile App
			regex = /^\/mobile-cle-app$/;
			if (regex.test(path)) {
				contentGroup = 'Mobile App';
				break;
			}

			// Free CLE	
			regex = /^\/free-continuing-legal-education$/;
			if (regex.test(path)) {
				contentGroup = 'Free CLE';
				break;
			}

			break;
	}

	return contentGroup;
}

const getDurationISO8601 = (durationSeconds) => {

	const hours = Math.floor(durationSeconds / 3600);
	durationSeconds %= 3600;
	const minutes = Math.floor(durationSeconds / 60);
	const seconds = durationSeconds % 60;

	const duration = serialize({
		hours: hours,
		minutes: minutes,
		seconds: seconds
	});

	return duration;
}

// const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i
const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const pluralize = (text, count, pluralForm) => {

	let returnValue = '';

	count = parseInt(count);

	// Plural form different from text+s
	if (pluralForm) {
		returnValue = `${count === 1 ? text : pluralForm}`
	} else {
		returnValue = `${text}${count === 1 ? '' : 's'}`
	}

	return returnValue;
}

// Format: July 15, 2023
const formatMonthDateYear = (date) => {
	const formattedDate = date.toLocaleDateString({}, { month: "long", day: "2-digit", year: "numeric" });
	// const dateParts = formattedDate.split(' ');

	return formattedDate;
	// return `${dateParts[1].replace(',', '')} ${dateParts[0]}, ${dateParts[2]}`;
}


// Navigate to state bars section of My Account page
const navigateToMyAccountStateBars = (history) => {
	history.push('/secure/my-account');

	setTimeout(() => {
		let barInfoSection = document?.getElementById('BarInfo');

		var i = 0;
		// store the interval id to clear in future
		var intr = setInterval(function () {
			// console.log('from interval i=', i);
			if (!barInfoSection) {
				// console.log('no barInfoSection found');
				barInfoSection = document?.getElementById('BarInfo');
			}
			else {
				// console.log('barInfoSection found, scrolling into view');
				barInfoSection.scrollIntoView({ behavior: "smooth" });
				clearInterval(intr);
			}
			// clear the interval if `i` reached 100
			if (++i === 100) clearInterval(intr);
		}, 200)
	}, 200);
}

// Compose practice area options for secure pages practice area filter
const getPracticeAreaOptions = (user) => {

	const _practiceAreasSeparator = '────────────────────────';

	let options = [
		{ value: '', label: 'All Practice Areas' },
		{ label: _practiceAreasSeparator }
	];

	const topPracticeAreas = user.AllPracticeAreas && 
		user.AllPracticeAreas.filter(practiceArea => practiceArea.IsStateSpecialty === true)
			.map((practice) => {
				return {
					value: practice?.ID?.toString(),
					label: practice?.Name,
					friendlyURL: practice?.FriendlyURL,
					isStateSpecialty: practice?.IsStateSpecialty,
					isMCLE: practice?.IsMCLE,
					requiredHours: practice?.RequiredHours,
					stateAbbreviation: practice?.StateAbbreviation,
					stateID: practice?.StateID,
					stateName: practice?.StateName,
					specialtyName: practice?.SpecialtyName
				}
			});

	if (!isEmpty(topPracticeAreas))
		options.push(...topPracticeAreas.concat({ label: _practiceAreasSeparator }));

	const middlePracticeAreas = user.AllPracticeAreas && user
		.AllPracticeAreas
		.filter(practiceArea => practiceArea.IsFavorite === true && practiceArea.IsStateSpecialty === false)
		.map((practice) => {
			return {
				value: practice?.ID?.toString(),
				label: practice?.Name,
				friendlyURL: practice?.FriendlyURL,
				isStateSpecialty: practice?.IsStateSpecialty,
				isMCLE: practice?.IsMCLE,
				requiredHours: practice?.RequiredHours,
				stateAbbreviation: practice?.StateAbbreviation,
				stateID: practice?.StateID,
				stateName: practice?.StateName,
				specialtyName: practice?.SpecialtyName
			}
		});

	if (!isEmpty(middlePracticeAreas))
		options.push(...middlePracticeAreas.concat({ label: _practiceAreasSeparator }));

	const bottomPracticeAreas = user.AllPracticeAreas && user
		.AllPracticeAreas
		.filter(practiceArea => practiceArea.IsFavorite === false && practiceArea.IsStateSpecialty === false)
		.map((practice) => {
			return {
				value: practice?.ID?.toString(),
				label: practice?.Name,
				friendlyURL: practice?.FriendlyURL,
				isStateSpecialty: practice?.IsStateSpecialty,
				isMCLE: practice?.IsMCLE,
				requiredHours: practice?.RequiredHours,
				stateAbbreviation: practice?.StateAbbreviation,
				stateID: practice?.StateID,
				stateName: practice?.StateName,
				specialtyName: practice?.SpecialtyName
			}
		});

	if (!isEmpty(bottomPracticeAreas))
		options.push(...bottomPracticeAreas);

	return options;
}


// Combine path:string with site root route 
// (can improve: add isDev env conditional and return bet.lexvid instead) 
const getAbsoluteUrl = (path) => {
	return new URL(path, "https://lexvid.com").href;
}

// Example
// debounceBtn.addEventListener('click', debounce(function () {
// 	console.info('Hey! It is', new Date().toUTCString());
// }, 3000));
const debounce = (func, delay) => {
	let inDebounce
	return function () {
		const context = this
		const args = arguments
		clearTimeout(inDebounce)
		inDebounce = setTimeout(() => func.apply(context, args), delay)
	}
}

// Example
// throttleBtn.addEventListener('click', throttle(function () {
// 	return console.log('Hey! It is', new Date().toUTCString());
// }, 1000));
const throttle = (func, limit) => {
	let lastFunc
	let lastRan
	return function () {
		const context = this
		const args = arguments
		if (!lastRan) {
			func.apply(context, args)
			lastRan = Date.now()
		} else {
			clearTimeout(lastFunc)
			lastFunc = setTimeout(function () {
				if ((Date.now() - lastRan) >= limit) {
					func.apply(context, args)
					lastRan = Date.now()
				}
			}, limit - (Date.now() - lastRan))
		}
	}
}

function decodeHtml(html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
}

function getPageName(path, states) {

	let pageName = '';
	path = path.toLowerCase().replace(/^\//, "");

	const isMultiState = states?.multiStateArray?.length > 1;
	// console.log(path);

	if (path.includes('/reviews')) return 'course-reviews';

	switch (path) {
		case '/':
		case '':
			pageName = 'home';
			break;
		case '/cle-speakers':
		case 'cle-speakers':
			pageName = 'speakers';
			break;
		case 'privacy-policy':
		case '/privacy-policy':
			pageName = 'Privacy Policy';
			break;
		case '/law-firm-cle':
			pageName = 'lawfirm-packages';
			break;
		default:
			// Get the pageName for paths like /secure and /secure/pageName
			const pathParts = path.split('/');

			if (pathParts.length === 2 && pathParts[0].toLowerCase() === 'blog') {
				return 'blogDetails';
			}

			if (pathParts.length === 2 && pathParts[0] === 'cle-courses') {
				return 'courses';
			}

			if (pathParts.length === 2 && pathParts[0] === 'cle-course') {
				return 'cle-course-' + pathParts[1];
			}

			if (pathParts.length === 2 && pathParts[0] === 'cle-speakers') {
				return 'cle-speakers-' + pathParts[1];
			}


			if (pathParts.length === 3 && pathParts[0] === 'cle' && pathParts[2] === 'reviews')
				return 'course-reviews';

			if (pathParts.length === 2) {
				// part of the URL after /
				const urlPart1 = pathParts[1].toLowerCase();

				const isStatePage = isStateFriendlyUrl(urlPart1, states);
				// console.log(isStatePage);

				if (isStatePage) {
					return 'state';
				}

				if (states?.stateURL && states?.stateURL.toLowerCase() === urlPart1) {
					pageName = 'state';
				} else {
					if (pathParts[0] === 'cle-speaker' && pathParts[1] === 'application') return 'speaker-application';
					else pageName = pathParts[1].toLowerCase() === 'secure' ? 'home' : pathParts[1].toLowerCase();
				}
			} else if (pathParts.length > 2) {

				if (states?.stateURL && states?.stateURL.toLowerCase() === pathParts[1].toLowerCase()) {
					pageName = pathParts[2].toLowerCase();
				} else if (pathParts[pathParts.length - 1] === 'custom') {

					if (isStateFriendlyUrl(pathParts[0].toLowerCase(), states))
						pageName = 'product-custom';
					else if (pathParts[0].toLowerCase() === 'secure' && pathParts[1].toLowerCase() === 'packages')
						pageName = 'secure-product-custom';
					else pageName = 'multistate-product-custom';

				} else if (pathParts[pathParts.length - 1] === 'custom-live') {
					
					pageName = isMultiState ? 'product-custom-live-multistate' : 'product-custom-live';
				
				} else if (pathParts[pathParts.length - 1] === 'unlimited') {

					pageName = isMultiState ? 'multistate-product-unlimited' : 'product-unlimited';

				} else if (pathParts[pathParts.length - 1] === 'unlimited-live') {

					pageName = isMultiState ? 'Product-Unlimited-Live-Multistate' : 'product-unlimited-live';

				} else if (pathParts[pathParts.length - 1] === 'all-inclusive') {

					pageName = isMultiState ? 'Product-All-Inclusive-Multistate' : 'product-all-inclusive';

				} else if (pathParts[pathParts.length - 1] === 'a-la-carte' || pathParts[pathParts.length - 2] === 'a-la-carte' || pathParts[pathParts.length - 3] === 'a-la-carte') {

					if (isStateFriendlyUrl(pathParts[0].toLowerCase(), states))
						pageName = 'product-a-la-carte';
					else if (pathParts[0].toLowerCase() === 'secure' && pathParts[1].toLowerCase() === 'packages')
						pageName = 'secure-product-a-la-carte';
					else pageName = 'multistate-product-a-la-carte';

				} else {
					pageName = pathParts[1].toLowerCase() === 'secure' ? pathParts[2].toLowerCase() : pathParts[1].toLowerCase();
				}
			} else if (pathParts.length === 1) {
				const isStatePage = isStateFriendlyUrl(pathParts[0].toLowerCase(), states);
				if (isStatePage) return 'state';
				else return pathParts[0].toLowerCase();
			}
			break;
	}
	return pageName;
}

const isStateFriendlyUrl = (url, states) => {
	return states?.items?.filter(i => i.FriendlyURL === url)?.length > 0;
}

const getHoursAndMinutes = (seconds) => {
	const hours = Math.floor(seconds / 60 / 60);

	const minutes = Math.round(seconds / 60) - (hours * 60);

	const formatted = hours > 0 ? hours + 'h ' + minutes + 'min' : minutes + 'min';

	return formatted;
}

const formatDate = (dateString) => {
	const date = new Date(dateString);
	const formatted = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();

	return formatted;
}

const formatDateToMonthDDYYYY = (dateString) => {
	const date = new Date(dateString);

	const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    

	const formatted = monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();

	return formatted;
}

const formatShortDateUS = (dateString) => {
	const date = new Date(dateString);
	let dateFormatted = '';
	try {
		if (date instanceof Date) dateFormatted = new Intl.DateTimeFormat('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' }).format(date);
	} catch (error) {

	}

	return dateFormatted;
}

const formatMoney = (amount) => {

	if (!amount) return '';

	let moneyFormatted = `$`;
	moneyFormatted += Number.isInteger(amount) ? amount : amount.toFixed(2);

	return moneyFormatted;
}

const validateStateBar = (stateBar) => {

	let isValid = false;
	// console.log(stateBar);

	if (stateBar.bar?.DeadlineSelectMode === "NONE") {
		isValid = true;
	} else if (stateBar.bar?.DeadlineSelectMode === "GROUP") {
		isValid = !!stateBar.groupId?.value;
	} else if (stateBar.bar?.DeadlineSelectMode === "YEAR") {
		isValid = !!stateBar.year?.value;
	} else if (stateBar.bar?.DeadlineSelectMode === "YEAR_MONTH_DAY") {
		isValid = !!stateBar?.day?.value && !!stateBar.year?.value && !!stateBar.month?.value;
	} else if (stateBar.bar?.DeadlineSelectMode === "YEAR_MONTH") {
		isValid = !!stateBar.year?.value && !!stateBar.month?.value;
	} else if (stateBar.bar?.DeadlineSelectMode === "MONTH") {
		isValid = !!stateBar.month?.value;
	}

	return isValid;
}


const toCurrency = (inputValue) => {
	let decimalValue = parseFloat(inputValue)
	let intValue = parseInt(inputValue)

	if (decimalValue === intValue)
		return intValue
	else
		return decimalValue.toFixed(2);
}

const getUserInitials = (user) => {
	return user ? `${user.FirstName?.substring(0, 1)}${user.LastName?.substring(0, 1)}` : '';
}

// moving this into smarter hook, which understands if user has unlim or liveCredits available and shows appropriate text and price + button action
const getSelectedLiveCoursesValues = (courses, selectedEvents, user) => {
	if(isEmpty(selectedEvents)) return { numberOfHours: 0, price: 0};

	const selectedCoursesValues = courses?.reduce((accumulator, liveCourse) => { 
		let anyEventTimeSelected = false;
		liveCourse.LiveEvents.forEach(liveEvent => { 
			if(selectedEvents.includes(liveEvent.LiveEventsID)) anyEventTimeSelected = true;
		});
		if (anyEventTimeSelected) {
			accumulator.numberOfHours += liveCourse.LiveHours;
			const priceToAdd = (liveCourse.LivePriceDiscounted > 0 && liveCourse.LivePrice !== liveCourse.LivePriceDiscounted) ? 
				liveCourse.LivePriceDiscounted : liveCourse.LivePrice;
			accumulator.price += priceToAdd;
		} 
		return accumulator;
	}, { numberOfHours: 0, price: 0});

	selectedCoursesValues.priceFormatted = "$" + selectedCoursesValues.price;

	return selectedCoursesValues;
}

const getStateByID = (states, stateID) => {
	return states.find(state => state.ID === stateID);
}

// don't show OD upsell if they have >= 3 on demand credits (PaidCredits) or a bundle or unlim
const notShowODUpsell = (user) => {
	if (!user) return false;

	const currentDate = new Date();
	return (user?.HasUnlimited && new Date(user?.UnlimitedExpiration) >= currentDate ) || user?.TotalBundleHours > 0 || user?.PaidCredits >= 3;
}

// customer already has live credits or scheduled live courses for the live upsell, 
const notShowLiveUpsell = (user) => {
	if (!user) return false;

	const currentDate = new Date();
	return user?.LiveCredits > 0 || !isEmpty(user?.UserLiveEvents) || (user?.HasUnlimited && user?.IsLiveUnlimited === true && new Date(user?.UnlimitedExpiration) >= currentDate);
}

export default {
	decodeHtml,
	getPageName,
	getHoursAndMinutes,
	formatDate,
	formatDateToMonthDDYYYY,
	formatShortDateUS,
	formatMoney,
	validateStateBar,
	toCurrency,
	debounce,
	throttle,
	getAbsoluteUrl,
	getPracticeAreaOptions,
	navigateToMyAccountStateBars,
	formatMonthDateYear,
	pluralize,
	getUserInitials,
	getSelectedLiveCoursesValues,
	getStateByID, 
	notShowODUpsell,
	notShowLiveUpsell,
	emailPattern,
	getDurationISO8601,
	getContentGroup
};