import React, { useEffect } from 'react';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';


const KayakoChat = (props) => {

	const { autoOpen } = props;
	const location = useLocation();

	useEffect(() => {

		(function (d, a) { function c() { var b = d.createElement("script"); b.async = !0; b.type = "text/javascript"; b.src = a._settings.messengerUrl; b.crossOrigin = "anonymous"; var c = d.getElementsByTagName("script")[0]; c.parentNode.insertBefore(b, c) } window.kayako = a; a.readyQueue = []; a.newEmbedCode = !0; a.ready = function (b) { a.readyQueue.push(b) }; a._settings = { apiUrl: "https://lexvid.kayako.com/api/v1", messengerUrl: "https://lexvid.kayakocdn.com/messenger", realtimeUrl: "wss://kre.kayako.net/socket" }; window.attachEvent ? window.attachEvent("onload", c) : window.addEventListener("load", c, !1) })(document, window.kayako || {});

		// console.log('useEffect with kayako.config')

		window.kayako.config = {
			hideLauncher: false,
			hideReplyTime: false,
			styles: {
				//primaryColor: '#1d3f59'
			}
		}

		return () => {
			// console.log('return from kayako useEffect call')
			if (window.kayako && typeof window.kayako.hide === "function") {
				window.kayako.hide()
			};
		}
	}, [])

	useEffect(() => {

		// console.log('location change');

		if (window.kayako && typeof window.kayako.show === "function") {
			// console.log('calling k show')
			window.kayako.show();
		}

	}, [location])


	useEffect(() => {

		if (autoOpen) {
			window.kayako.ready(function () {
				if ($(window).width() > 990) {
					console.log("Timeout started")
					setTimeout(function () {
						window.kayako.show();
						window.kayako.maximize();
					}, 6000)
				}
			})
		}

	}, [autoOpen])

	return <script async="" type="text/javascript" src="https://lexvid.kayakocdn.com/messenger" crossOrigin="anonymous"></script>
}

export default KayakoChat;