import { useEffect } from "react";
// import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import utils from "../utils";


export default function useSendPageViewGA() {

  const locationWithSearch =  window.location.pathname + window.location.search;
  const path = window.location.pathname;

  useEffect(() => {
    if (!locationWithSearch.includes("/loading")) {
      
      // console.log("Sending page view to GA/GTM: " + locationWithSearch);    
      
      const contentGroup = utils.getContentGroup(path);
console.log("contentGroup sent: ", contentGroup);
      // const gaData = {
      //   hitType: "page_view",
      //   page: locationWithSearch,
      //   contentGroup: contentGroup
      // }

      // // console.log("Sending page view to GA4: ", gaData);

      // ReactGA.send(gaData);

      // 

      const dataLayerData = {
        // event: "page_view", 
        // page: locationWithSearch,
        section_of_website: contentGroup
      }

      // console.log("Sending page view to GTM: ", dataLayerData);

      TagManager.dataLayer({
        dataLayer: dataLayerData
      });

    }
  }, [locationWithSearch, path]);
}