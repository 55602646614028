import {
    UPDATE_STATE_URL,
    GET_STATES,
    GET_STATE,
    GET_STATE_SUCCESS,
    GET_DEFAULT_STATE_SUCCESS,
    UPDATE_STATE_ID,
    GET_STATE_REQUIREMENTS,
    GET_STATE_FAQS,
    GET_STATE_FAQS_SUCCESS,
    GET_STATE_DISCOUNTS,
    GET_STATE_DISCOUNTS_SUCCESS,
    GET_MULTISTATE,
    GET_MULTISTATE_SUCCESS,
    RESET_STATE,
    GET_LIVE_COURSES_SUCCESS,
} from '../actions/types';

const initialState = {
    items: [],
    stateURL: '',
    stateID: '',
    stateObject: {},
    defaultStateObject: {},
    error: '',
    stateFAQS: [],
    isLoading: false,
    stateRequirements: {},
    stateDiscounts: {},
    multiStateArray: [],
    liveCourses: [],
    isLoadingState: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LIVE_COURSES_SUCCESS: 
        {
            return {
                ...state,
                liveCourses: action.payload
            }
        }
        case GET_STATES:
            {
                return {
                    ...state,
                    items: action.payload
                }
            }
        case GET_STATE:
            {
                return {
                    ...state,
                    isLoadingState: true
                }
            }
        case GET_STATE_SUCCESS:
            {
                return {
                    ...state,
                    stateObject: action.payload,
                    isLoadingState: false
                }

            }
        case RESET_STATE:
            {
                return {
                    ...state,
                    stateURL: '',
                    stateID: '',
                    stateObject: {},
                    stateFAQS: [],
                    isLoading: false,
                    stateRequirements: {},
                    stateDiscounts: {},
                    isLoadingState: false
                }
            }
        case GET_DEFAULT_STATE_SUCCESS:
            {
                return {
                    ...state,
                    defaultStateObject: action.payload,
                }
            }
        case UPDATE_STATE_URL:
            {
                return {
                    ...state,
                    stateURL: action.payload
                }
            }
        case UPDATE_STATE_ID:
            {
                return {
                    ...state,
                    stateID: action.payload
                }
            }
        case GET_STATE_FAQS:
            {
                return {
                    ...state,
                    isLoading: true
                }
            }
        case GET_STATE_FAQS_SUCCESS:
            {
                return {
                    ...state,
                    stateFAQS: action.payload,
                    isLoading: false
                }
            }
        case GET_STATE_REQUIREMENTS:
            {
                return {
                    ...state,
                    stateRequirements: action.payload
                }
            }
        case GET_STATE_DISCOUNTS:
            {
                return {
                    ...state,
                    isLoading: true
                }
            }
        case GET_STATE_DISCOUNTS_SUCCESS:
            {
                return {
                    ...state,
                    stateDiscounts: action.payload,
                    isLoading: false
                }
            }
        case GET_MULTISTATE:
            {
                return {
                    ...state,
                    isLoading: true
                }
            }
        case GET_MULTISTATE_SUCCESS:
            {
                return {
                    ...state,
                    multiStateArray: action.payload,
                    isLoading: false
                }
            }
        default:
            return state;
    }
}