import { useSelector } from "react-redux";
import { isEmpty } from 'lodash';

const useShowLiveEvents = (props) => {

	const { isMultiState, includeLiveNotRequired = false } = props;

	const multiStateArray = useSelector(state => state.states.multiStateArray);
	const stateObjectFromStore = useSelector(state => state.states.stateObject);
	const defaultStateObject = useSelector(state => state.states.defaultStateObject);

	const stateObject = isEmpty(stateObjectFromStore) ? defaultStateObject : stateObjectFromStore;

	if (includeLiveNotRequired) {
		return isMultiState ? multiStateArray?.some(s => s.HasLiveEvents === true) : stateObject?.HasLiveEvents;
	} else {
		return isMultiState ? multiStateArray?.some(s => s.HasLiveEvents === true && s.IsLiveRequired) : (stateObject?.HasLiveEvents && stateObject?.IsLiveRequired);
	}
};

export default useShowLiveEvents;