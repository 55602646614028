import React from 'react';
import { Helmet } from "react-helmet";

const NoIndexMeta = (props) => {

  return <Helmet>
    <meta name="robots" content="noindex" />
    <meta name="googlebot" content="noindex" />
  </Helmet>

}

export default NoIndexMeta;