import {
	LOGIN, LOGIN_SUCCESS, LOGIN_FAILED,
	LOGOUT, LOGOUT_SUCCESS,
	SET_USER, UNSET_USER, SET_OFFLINE_USER,
	SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILED, SIGNUP_RESET,
	SET_ADMIN, UNSET_ADMIN,
	FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILED,
	UPDATE_USER, CHECKOUT, CHECKOUT_SUCCESS, CHECKOUT_FAILED, SET_COOKIES_ACCEPTED,
	REFRESH_USER, UPDATE_IS_PARALEGAL
} from '../actions/types';

const initialState = {
	offline_guid: null,
	user: null,
	token: null,
	status: null, // 'none', 'loading', 'success', 'error'
	error: null,
	isAdmin: false,
	forgotPassword: null,
	cookiesAccepted: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			{
				return {
					...state,
					status: 'loading'
				}

			}
		case LOGIN_SUCCESS:
			{
				return {
					...state,
					status: 'success',
				}
			}
		case LOGIN_FAILED:
			{
				return {
					...state,
					status: 'error',
					user: null,
					token: null,
					error: action.payload
				}
			}
		case LOGOUT:
			{
				return {
					...state,
					status: 'loading'
				}
			}
		case LOGOUT_SUCCESS:
			{
				return {
					...state,
					status: 'none',
					user: null,
					token: null,
				}
			}
		case SET_USER:
			{
				return {
					...state,
					status: 'success',
					user: action.payload.user,
					token: action.payload.token,
					offline_guid: null,
				}
			}
		case UNSET_USER:
			{
				return {
					...state,
					status: 'none',
					user: null,
					token: null,
				}
			}
		case SET_OFFLINE_USER:
			{
				return {
					...state,
					offline_guid: action.payload,
				}
			}
		case SIGNUP:
			{
				return {
					...state,
					error: null,
					status: 'loading'
				}
			}
		case SIGNUP_SUCCESS:
			{
				return {
					...state,
					status: 'success',
				}
			}
		case SIGNUP_FAILED:
			{
				return {
					...state,
					status: 'error',
					user: null,
					token: null,
					error: action.payload
				}
			}
		case SIGNUP_RESET:
			{
				return {
					...state,
					status: 'none',
					error: null
				}
			}
		case SET_ADMIN:
			{
				return {
					...state,
					isAdmin: true
				}
			}
		case UNSET_ADMIN:
			{
				return {
					...state,
					isAdmin: false
				}
			}
		case FORGOT_PASSWORD:
			{
				return { ...state, error: null, status: 'loading' }
			}
		case FORGOT_PASSWORD_SUCCESS:
			{
				return {
					...state,
					forgotPassword: action.payload,
					status: 'success'
				}
			}
		case FORGOT_PASSWORD_FAILED:
			{
				return { ...state, error: action.payload, status: 'error' }
			}
		case UPDATE_USER:
			{
				return {
					...state,
					user: action.payload
				}
			}
		case CHECKOUT:
			{
				return {
					...state,
					error: null,
					status: 'loading'
				}
			}
		case CHECKOUT_SUCCESS:
			{
				return {
					...state,
					status: 'success',
				}
			}
		case CHECKOUT_FAILED:
			{
				return {
					...state,
					status: 'error',
					user: null,
					token: null,
					error: action.payload
				}
			}
		case SET_COOKIES_ACCEPTED:
			{
				return {
					...state,
					cookiesAccepted: action.payload
				}
			}
		case REFRESH_USER:
			{
				return {
					...state,
					user: action.payload
				}
			}
		case UPDATE_IS_PARALEGAL:
			{
				return {
					...state,
					user: {
						...state.user,
						IsParalegal: action.payload
					}
				}
			}
		default:
			return state;
	}
}